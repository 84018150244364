// client/src/App.js
import './App.css';

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { register } from './serviceWorkerRegistration.js';
import { NotificationProvider } from './contexts/NotificationContext';
import Notifications from './components/Notifications';
import { ErrorProvider } from './contexts/ErrorContext';
import ErrorDisplay from './components/ErrorDisplay';
import { ObjectsProvider } from './contexts/ObjectsContext';
import { ClerkProvider, SignedIn, SignedOut, SignInButton, UserButton, useAuth } from "@clerk/clerk-react";
import { ExtensionProvider } from './contexts/ExtensionContext';

import AppPage from './pages/AppPage.js';
import FilePage from './pages/FilePage.js';
import ModulesPage from './pages/ModulesPage.js';
import Dashboard from './pages/Dashboard.js';
import ObjectsPage from './pages/ObjectsPage.js';
import ConversationPage from './pages/ConversationPage.js';
import NotePage from './pages/NotePage.js';
import ObjectPage from './pages/ObjectPage.js';
import JobPage from './pages/JobPage.js';
import ScrollToArrows from './components/ScrollToArrows';
import CanvasPage from './pages/CanvasPage.js';
import EventsPage from './pages/EventsPage';
import EventPage from './pages/EventPage';
import ObjectLoader from './components/ObjectLoader';
import ObjectEditor from './components/ObjectEditor.js';
import AutoDiaryModulePage from './pages/modules/AutoDiaryModulePage.js';
import LifeAreasModulePage from './pages/modules/LifeAreasModulePage.js';
import ClosePeopleModulePage from './pages/modules/ClosePeopleModulePage.js';
import GratitudeJarModulePage from './pages/modules/GratitudeJarModulePage.js';
import DataAnalysisModulePage from './pages/modules/DataAnalysisModulePage.js';
import CapitalPredictionModulePage from './pages/modules/CapitalPredictionModulePage.js';
import PhotosPage from './pages/PhotosPage.js';
import SettingsPage from './pages/SettingsPage';
import DailyCompanionPage from './pages/modules/DailyCompanionPage';
import BrowserHistoryPage from './pages/BrowserHistoryPage';

// Create a protected route wrapper component
function ProtectedRoute({ children }) {
  const { isLoaded, isSignedIn } = useAuth();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!isSignedIn) {
    return <Navigate to="/" />;
  }

  return children;
}

function App() {
  const [waitingWorker, setWaitingWorker] = useState(null);
  const CLERK_PUBLISHABLE_KEY = 'pk_test_cGxlYXNhbnQtYm9uZWZpc2gtMzcuY2xlcmsuYWNjb3VudHMuZGV2JA';

  if (!CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Clerk Publishable Key");
  }

  useEffect(() => {
    // Register service worker update handler
    if ('serviceWorker' in navigator) {
      const handleServiceWorkerUpdate = (registration) => {
        setWaitingWorker(registration.waiting);
      };

      register({
        onUpdate: handleServiceWorkerUpdate,
      });
    }
  }, []);

  return (
    <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
      <ErrorProvider>
        <NotificationProvider>
          <ObjectsProvider>
            <ExtensionProvider>
              <Router>
                <header className="bg-gray-800 text-white">
                  <nav className="container mx-auto px-4 py-3 flex justify-between items-center">
                    <SignedIn>
                      <div className="flex items-center space-x-4">
                        <Link to="/dashboard" className="hover:text-gray-300">Dashboard</Link>
                        <Link to="/objects" className="hover:text-gray-300">Objects</Link>
                        <Link to="/modules" className="hover:text-gray-300">Modules</Link>
                        <Link to="/events" className="hover:text-gray-300">Events</Link>
                        <Link to="/photos" className="hover:text-gray-300">Photos</Link>
                        <Link to="/history" className="hover:text-gray-300">History</Link>
                        <Link to="/settings" className="hover:text-gray-300">Settings</Link>
                      </div>
                    </SignedIn>
                    <div className="flex items-center space-x-4">
                      <SignedOut>
                        <SignInButton mode="modal" />
                      </SignedOut>
                      <SignedIn>
                        <UserButton
                          afterSignOutUrl="/"
                          appearance={{
                            elements: {
                              userButtonAvatarBox: "w-10 h-10"
                            }
                          }}
                        />
                      </SignedIn>
                    </div>
                  </nav>
                </header>
                <Notifications />
                <main className="container mx-auto px-4 py-4">
                  <ErrorDisplay />
                  <Routes>
                    <Route path="/" element={
                      <>
                        <SignedIn>
                          <Navigate to="/dashboard" />
                        </SignedIn>
                        <SignedOut>
                          <div className="flex items-center justify-center h-screen">
                            <SignInButton mode="modal" />
                          </div>
                        </SignedOut>
                      </>
                    } />

                    {/* Protect all other routes */}
                    <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                    <Route path="/objects" element={<ProtectedRoute><ObjectsPage /></ProtectedRoute>} />
                    <Route path="/modules" element={<ProtectedRoute><ModulesPage /></ProtectedRoute>} />
                    <Route path="/notes/:objectId" element={
                      <ProtectedRoute>
                        <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving) =>
                          <ObjectEditor
                            object={object}
                            setObject={setObject}
                            saveEdits={saveEdits}
                            component={() =>
                              <NotePage
                                note={object}
                                setNote={setObject}
                                noteId={objectId}
                                saveEdits={saveEdits}
                                isSaving={isSaving}
                              />}
                          />}
                        />
                      </ProtectedRoute>
                    } />
                    <Route path="/conversations/:objectId" element={
                      <ProtectedRoute>
                        <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving, loadObject) =>
                          <ObjectEditor
                            object={object}
                            setObject={setObject}
                            saveEdits={saveEdits}
                            component={() =>
                              <ConversationPage
                                conversation={object}
                                setConversation={setObject}
                                conversationId={objectId}
                                saveEdits={saveEdits}
                                isSaving={isSaving}
                                loadObject={loadObject}
                              />}
                          />}
                        />
                      </ProtectedRoute>
                    } />
                    <Route path="/jobs/:objectId" element={
                      <ProtectedRoute>
                        <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving, loadObject) =>
                          <ObjectEditor
                            object={object}
                            setObject={setObject}
                            saveEdits={saveEdits}
                            component={() =>
                              <JobPage
                                job={object}
                                setJob={setObject}
                                jobId={objectId}
                                saveEdits={saveEdits}
                                isSaving={isSaving}
                                loadObject={loadObject}
                              />}
                          />}
                        />
                      </ProtectedRoute>
                    } />
                    <Route path="/objects/:objectId" element={
                      <ProtectedRoute>
                        <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving) =>
                          <ObjectEditor
                            object={object}
                            setObject={setObject}
                            saveEdits={saveEdits}
                            component={() =>
                              <ObjectPage
                                object={object}
                                setObject={setObject}
                                objectId={objectId}
                                saveEdits={saveEdits}
                                isSaving={isSaving}
                              />}
                          />}
                        />
                      </ProtectedRoute>
                    } />
                    <Route path="/apps/:objectId" element={
                      <ProtectedRoute>
                        <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving) =>
                          <ObjectEditor
                            object={object}
                            setObject={setObject}
                            saveEdits={saveEdits}
                            component={() =>
                              <AppPage
                                app={object}
                                setApp={setObject}
                                appId={objectId}
                                saveEdits={saveEdits}
                                isSaving={isSaving}
                              />}
                          />}
                        />
                      </ProtectedRoute>
                    } />
                    <Route path="/files/:objectId" element={
                      <ProtectedRoute>
                        <ObjectLoader component={(object, setObject, objectId, saveEdits) =>
                          <ObjectEditor
                            object={object}
                            setObject={setObject}
                            saveEdits={saveEdits}
                            component={() =>
                              <FilePage
                                object={object}
                                setObject={setObject}
                                objectId={objectId}
                                saveEdits={saveEdits}
                              />}
                          />}
                        />
                      </ProtectedRoute>
                    } />
                    <Route path="/canvases/:objectId" element={
                      <ProtectedRoute>
                        <ObjectLoader component={(object, setObject, objectId, saveEdits) =>
                          <ObjectEditor
                            object={object}
                            setObject={setObject}
                            saveEdits={saveEdits}
                            component={() =>
                              <CanvasPage
                                object={object}
                                setObject={setObject}
                                objectId={objectId}
                                saveEdits={saveEdits}
                              />}
                          />}
                        />
                      </ProtectedRoute>
                    } />
                    <Route path="/events/:database_id" element={<ProtectedRoute><EventPage /></ProtectedRoute>} />
                    <Route path="/events" element={<ProtectedRoute><EventsPage /></ProtectedRoute>} />
                    <Route path="/modules/auto-diary" element={<ProtectedRoute><AutoDiaryModulePage /></ProtectedRoute>} />
                    <Route path="/modules/life-areas" element={<ProtectedRoute><LifeAreasModulePage /></ProtectedRoute>} />
                    <Route path="/modules/close-people" element={<ProtectedRoute><ClosePeopleModulePage /></ProtectedRoute>} />
                    <Route path="/modules/gratitude-jar" element={<ProtectedRoute><GratitudeJarModulePage /></ProtectedRoute>} />
                    <Route path="/modules/data-analysis" element={<ProtectedRoute><DataAnalysisModulePage /></ProtectedRoute>} />
                    <Route path="/modules/capital-prediction" element={<ProtectedRoute><CapitalPredictionModulePage /></ProtectedRoute>} />
                    <Route path="/modules/daily-companion" element={<ProtectedRoute><DailyCompanionPage /></ProtectedRoute>} />
                    <Route path="/photos" element={<ProtectedRoute><PhotosPage /></ProtectedRoute>} />
                    <Route path="/history" element={<ProtectedRoute><BrowserHistoryPage /></ProtectedRoute>} />
                    <Route path="/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
                  </Routes>
                </main>
                <footer id="footer" className="bg-gray-800 text-white text-center py-4">
                  &copy; {new Date().getFullYear()} Personal Canvas
                </footer>
                <ScrollToArrows />
              </Router>
            </ExtensionProvider>
          </ObjectsProvider>
        </NotificationProvider>
      </ErrorProvider>
    </ClerkProvider>
  );
}

export default App;
