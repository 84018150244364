// client/src/components/ObjectsPage.js

import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ObjectItem from '../components/ObjectItem';
import { useObjects } from '../contexts/ObjectsContext';
import { usePageTitle } from '../hooks/usePageTitle';

const ObjectsPage = () => {
  const { objects, getObjects, isLoading } = useObjects();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTags, setSearchTags] = useState(searchParams.get('search') || '');
  const [filteredObjects, setFilteredObjects] = useState([]);

  useEffect(()=>{
    getObjects();
  }, []);

  useEffect(() => {
    if (searchTags) {
      setSearchParams({ search: searchTags });
    } else {
      setSearchParams({});
    }
    handleTagSearch();
  }, [searchTags, objects]);

  const handleTagSearch = () => {
    if (!searchTags) {
      setFilteredObjects(objects);
      return;
    }

    const words = searchTags.split(',').map((word) => word.trim().toLowerCase());
    const filtered = objects.filter((obj) =>
      words.every((word) => obj.name.trim().toLowerCase().includes(word) || 
        obj.type.trim().toLowerCase().startsWith(word) || 
        obj.labels && obj.labels.map(x => x.trim().toLowerCase()).includes(word))
    );
    setFilteredObjects(filtered);
  };

  usePageTitle('Objects');

  return (
    <div className="objects-page">
      <h1 className="text-2xl font-bold mb-4">Objects</h1>
      <div className="mt-4">
        <h2 className="text-xl font-bold mb-2">Search by Tags</h2>
        <input
          type="text"
          value={searchTags}
          onChange={(e) => setSearchTags(e.target.value)}
          placeholder="Enter types or tags, comma-separated"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="mt-4">
        {filteredObjects.map((obj) => (
          <ObjectItem 
            key={obj.id} 
            object={obj}
          />
        ))}
        {objects.length ? (
          filteredObjects.length === 0 && (
            <p className="text-lg text-gray-600">No objects found</p>
          )
        ) : (
          <p className="text-lg text-gray-600">
            {isLoading ? 'Loading...' : 'No objects available'}
          </p>
        )}
      </div>
    </div>
  );
};

export default ObjectsPage;
