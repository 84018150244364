import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth, useUser } from '@clerk/clerk-react';

const ExtensionContext = createContext();

export function useExtension() {
  return useContext(ExtensionContext);
}

export function ExtensionProvider({ children }) {
  const [isExtensionConnected, setIsExtensionConnected] = useState(false);
  const { isSignedIn } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    if (isSignedIn && user) {
      // Send userId to extension via custom event
      const data = {
        type: 'SET_USER_ID',
        userId: user.id
      };
      
      // Create and dispatch event using modern CustomEvent constructor
      const customEvent = new CustomEvent('personalCanvasEvent', {
        bubbles: false,
        cancelable: false,
        detail: data
      });
      document.dispatchEvent(customEvent);

      // Listen specifically for the extension connected event
      const handleExtensionResponse = () => {
        setIsExtensionConnected(true);
      };

      document.addEventListener('EXTENSION_CONNECTED', handleExtensionResponse);

      return () => {
        document.removeEventListener('EXTENSION_CONNECTED', handleExtensionResponse);
      };
    } else {
      setIsExtensionConnected(false);
    }
  }, [isSignedIn, user]);

  const value = {
    isExtensionConnected
  };

  return (
    <ExtensionContext.Provider value={value}>
      {children}
    </ExtensionContext.Provider>
  );
} 