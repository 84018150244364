import React from 'react';
import { useUser, useAuth, UserProfile } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import SettingsManager from '../components/SettingsManager';
import { useExtension } from '../contexts/ExtensionContext';

function SettingsPage() {
  const { user } = useUser();
  const { isSignedIn, isLoaded } = useAuth();
  const { isExtensionConnected } = useExtension();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!isSignedIn) {
    return <Navigate to="/" />;
  }

  const microsoftSettings = {
    section: "Microsoft Integration",
    fields: [
      {
        label: "OneDrive Photo Paths",
        path: "integrations.microsoft.onedrive.imagePaths",
        type: "array",
        description: "Enter comma-separated folder paths to search for photos"
      }
    ]
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-8">Settings</h1>
      
      {/* Browser Extension Status */}
      <div className="mb-8 bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Browser Extension</h2>
        <div className="space-y-4">
          <div className="flex items-center">
            <div className={`w-3 h-3 rounded-full mr-3 ${isExtensionConnected ? 'bg-green-500' : 'bg-red-500'}`}></div>
            <span className="font-medium">
              {isExtensionConnected ? 'Extension Connected' : 'Extension Not Connected'}
            </span>
          </div>
          
          {!isExtensionConnected && (
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-medium mb-2">Installation Instructions:</h3>
              <ol className="list-decimal list-inside space-y-2 text-gray-600">
                <li>Download the Personal-Canvas Browser Extension</li>
                <li>Open Firefox and go to about:debugging</li>
                <li>Click "This Firefox" on the left sidebar</li>
                <li>Click "Load Temporary Add-on"</li>
                <li>Select the extension's manifest.json file</li>
              </ol>
            </div>
          )}
        </div>
      </div>

      {/* Clerk User Profile */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">User Profile</h2>
        <UserProfile />
      </div>

      {/* Custom Settings */}
      <div className="space-y-8">
        <SettingsManager {...microsoftSettings} />
      </div>
    </div>
  );
}

export default SettingsPage; 