import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useError } from '../../contexts/ErrorContext';

// Utility function to format currency
function formatCurrency(amount, currency = 'RON', exchangeRates = null) {
  if (!amount && amount !== 0) return '';
  
  let value = amount;
  if (currency !== 'RON' && exchangeRates && exchangeRates[currency]) {
    // Convert from RON to target currency
    value = amount * exchangeRates[currency];
  }
  
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2
  }).format(value);
}

// Monthly Summary Component
function MonthlySummary({ moduleData, formatWithSettings }) {
  const calculateMonthly = () => {
    if (!moduleData?.data) return { income: 0, expenses: 0, net: 0 };
    
    const { incomeSources, fixedBills, budget } = moduleData.data;
    let monthlyIncome = 0;
    let monthlyExpenses = 0;
    
    // Calculate monthly income
    incomeSources?.forEach(source => {
      switch (source.frequency) {
        case 'daily': monthlyIncome += source.amount * 30; break;
        case 'weekly': monthlyIncome += source.amount * 4.33; break;
        case 'monthly': monthlyIncome += source.amount; break;
        case 'yearly': monthlyIncome += source.amount / 12; break;
        default: break;
      }
    });
    
    // Calculate monthly expenses
    fixedBills?.forEach(bill => {
      switch (bill.frequency) {
        case 'daily': monthlyExpenses += bill.amount * 30; break;
        case 'weekly': monthlyExpenses += bill.amount * 4.33; break;
        case 'monthly': monthlyExpenses += bill.amount; break;
        case 'yearly': monthlyExpenses += bill.amount / 12; break;
        default: break;
      }
    });
    
    // Add budget to expenses
    if (budget?.amount) {
      switch (budget.frequency) {
        case 'daily': monthlyExpenses += budget.amount * 30; break;
        case 'weekly': monthlyExpenses += budget.amount * 4.33; break;
        case 'monthly': monthlyExpenses += budget.amount; break;
        case 'yearly': monthlyExpenses += budget.amount / 12; break;
        default: break;
      }
    }
    
    return {
      income: monthlyIncome,
      expenses: monthlyExpenses,
      net: monthlyIncome - monthlyExpenses
    };
  };

  const { income, expenses, net } = calculateMonthly();

  return (
    <div className="grid grid-cols-3 gap-4 mb-6">
      <div className="bg-green-50 p-4 rounded-lg">
        <div className="text-sm text-green-600">Monthly Income</div>
        <div className="text-xl font-bold text-green-700">{formatWithSettings(income)}</div>
      </div>
      <div className="bg-red-50 p-4 rounded-lg">
        <div className="text-sm text-red-600">Monthly Expenses</div>
        <div className="text-xl font-bold text-red-700">{formatWithSettings(expenses)}</div>
      </div>
      <div className={`p-4 rounded-lg ${net >= 0 ? 'bg-blue-50' : 'bg-yellow-50'}`}>
        <div className={net >= 0 ? 'text-sm text-blue-600' : 'text-sm text-yellow-600'}>
          Monthly Net
        </div>
        <div className={net >= 0 ? 'text-xl font-bold text-blue-700' : 'text-xl font-bold text-yellow-700'}>
          {formatWithSettings(net)}
        </div>
      </div>
    </div>
  );
}

// Capital Prediction Chart Component
function PredictionChart({ predictions, historicalData, formatWithSettings }) {
  if (!predictions || predictions.length === 0) return null;

  // Combine historical data with predictions for the chart
  const chartData = [];
  
  // Add historical data points
  if (historicalData && historicalData.length > 0) {
    historicalData.forEach(item => {
      chartData.push({
        date: item.date,
        historyAmount: item.amount,
        // Set prediction amounts to null for historical dates
        trendAmount: null,
        incomeAmount: null
      });
    });
  }
  
  // Add prediction data points
  predictions.forEach(item => {
    chartData.push({
      date: item.date,
      trendAmount: item.trendAmount,
      incomeAmount: item.incomeAmount,
      // For the first prediction point, also add historyAmount to connect lines
      historyAmount: chartData.length === historicalData?.length ? item.trendAmount : null
    });
  });
  
  // Sort data by date for continuous chart
  chartData.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <div className="bg-white p-4 rounded-lg shadow mb-6">
      <h3 className="text-lg font-semibold mb-4">Capital Prediction</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date" 
            tickFormatter={(date) => new Date(date).toLocaleDateString()}
          />
          <YAxis 
            tickFormatter={(value) => formatWithSettings(value)}
          />
          <Tooltip 
            formatter={(value) => typeof value === 'number' ? formatWithSettings(value) : value}
            labelFormatter={(date) => new Date(date).toLocaleDateString()}
          />
          <Legend />
          <Line 
            type="monotone" 
            dataKey="historyAmount" 
            stroke="#6366F1" 
            name="Historical Capital"
            strokeWidth={2}
            connectNulls
          />
          <Line 
            type="monotone" 
            dataKey="trendAmount" 
            stroke="#10B981" 
            name="Trend-based Prediction"
            strokeDasharray="5 5"
            connectNulls
          />
          <Line 
            type="monotone" 
            dataKey="incomeAmount" 
            stroke="#F59E0B" 
            name="Income-based Prediction"
            strokeWidth={2}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
      <div className="mt-4 text-sm text-gray-600">
        <p><span className="font-semibold">Historical Capital</span>: Your recorded capital evaluations.</p>
        <p><span className="font-semibold">Trend-based Prediction</span>: Based on the historical growth pattern of your capital.</p>
        <p><span className="font-semibold">Income-based Prediction</span>: Based on your defined income sources, fixed bills and budget.</p>
      </div>
    </div>
  );
}

// Form Modal Component
function FormModal({ show, title, onClose, children }) {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">{title}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            ✕
          </button>
        </div>
        {children}
      </div>
    </div>
  );
}

// Main Component
function CapitalPredictionModulePage() {
  const { setError } = useError();
  const [moduleData, setModuleData] = useState(null);
  const [loading, setLoading] = useState(false);
  
  // Form states
  const [evalForm, setEvalForm] = useState({
    date: new Date().toISOString().split('T')[0],
    amount: ''
  });
  const [incomeForm, setIncomeForm] = useState({
    name: '',
    amount: '',
    frequency: 'monthly',
    dayOfMonth: 1
  });
  const [billForm, setBillForm] = useState({
    name: '',
    amount: '',
    frequency: 'monthly',
    dayOfMonth: 1
  });
  const [budgetForm, setBudgetForm] = useState({
    amount: '',
    frequency: 'monthly'
  });
  
  // Modal states
  const [showEvalModal, setShowEvalModal] = useState(false);
  const [showIncomeModal, setShowIncomeModal] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  
  // Edit states
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);

  // Settings state
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [settingsForm, setSettingsForm] = useState({
    defaultCurrency: 'RON',
    multiCurrencyEnabled: false
  });

  // Target capital state
  const [targetCapital, setTargetCapital] = useState('');
  const [targetDates, setTargetDates] = useState({
    trendBased: null,
    incomeBased: null,
    meanBased: null
  });

  useEffect(() => {
    fetchModuleData();
  }, []);

  useEffect(() => {
    if (moduleData?.data?.targetCapital) {
      setTargetCapital(moduleData.data.targetCapital.toString());
      if (moduleData.data.predictionResults?.predictions) {
        calculateTargetDate();
      }
    }
  }, [moduleData]);

  async function fetchModuleData() {
    setLoading(true);
    try {
      const res = await axios.get('/api/objects', { withCredentials: true });
      const objects = res.data.objects;
      const found = objects.find(obj => obj.type === 'ModuleData' && obj.name === 'CapitalPredictionModule');
      setModuleData(found || null);
      
      // Update settings form with existing settings if available
      if (found?.data?.settings) {
        setSettingsForm({
          defaultCurrency: found.data.settings.defaultCurrency || 'RON',
          multiCurrencyEnabled: found.data.settings.multiCurrency?.enabled || false
        });
      }
    } catch (err) {
      console.error('Error fetching module data:', err);
      setError('Failed to fetch module data');
    } finally {
      setLoading(false);
    }
  }

  async function installModule() {
    setLoading(true);
    try {
      await axios.post('/api/modules/capitalPrediction/install', {}, { withCredentials: true });
      await fetchModuleData();
    } catch (err) {
      console.error('Error installing module:', err);
      setError('Failed to install module');
    } finally {
      setLoading(false);
    }
  }

  async function uninstallModule(keepData = false) {
    if (!window.confirm('Are you sure you want to uninstall this module?')) return;
    
    setLoading(true);
    try {
      await axios.post(
        '/api/modules/capitalPrediction/uninstall',
        { keepData },
        { withCredentials: true }
      );
      setModuleData(null);
    } catch (err) {
      console.error('Error uninstalling module:', err);
      setError('Failed to uninstall module');
    } finally {
      setLoading(false);
    }
  }

  async function handleAddEvaluation(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/api/modules/capitalPrediction/evaluation', evalForm, { withCredentials: true });
      setShowEvalModal(false);
      setEvalForm({ date: new Date().toISOString().split('T')[0], amount: '' });
      await fetchModuleData();
    } catch (err) {
      console.error('Error adding evaluation:', err);
      setError('Failed to add evaluation');
    } finally {
      setLoading(false);
    }
  }

  async function handleAddIncome(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/api/modules/capitalPrediction/income', incomeForm, { withCredentials: true });
      setShowIncomeModal(false);
      setIncomeForm({ name: '', amount: '', frequency: 'monthly', dayOfMonth: 1 });
      await fetchModuleData();
    } catch (err) {
      console.error('Error adding income:', err);
      setError('Failed to add income source');
    } finally {
      setLoading(false);
    }
  }

  async function handleAddBill(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/api/modules/capitalPrediction/bill', billForm, { withCredentials: true });
      setShowBillModal(false);
      setBillForm({ name: '', amount: '', frequency: 'monthly', dayOfMonth: 1 });
      await fetchModuleData();
    } catch (err) {
      console.error('Error adding bill:', err);
      setError('Failed to add bill');
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateBudget(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/api/modules/capitalPrediction/budget', budgetForm, { withCredentials: true });
      setShowBudgetModal(false);
      await fetchModuleData();
    } catch (err) {
      console.error('Error updating budget:', err);
      setError('Failed to update budget');
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteEvaluation(id) {
    if (!window.confirm('Are you sure you want to delete this evaluation?')) return;
    
    setLoading(true);
    try {
      await axios.delete('/api/modules/capitalPrediction/evaluation', { 
        data: { id },
        withCredentials: true 
      });
      await fetchModuleData();
    } catch (err) {
      console.error('Error deleting evaluation:', err);
      setError('Failed to delete evaluation');
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteIncome(id) {
    if (!window.confirm('Are you sure you want to delete this income source?')) return;
    
    setLoading(true);
    try {
      await axios.delete('/api/modules/capitalPrediction/income', { 
        data: { id },
        withCredentials: true 
      });
      await fetchModuleData();
    } catch (err) {
      console.error('Error deleting income source:', err);
      setError('Failed to delete income source');
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteBill(id) {
    if (!window.confirm('Are you sure you want to delete this fixed bill?')) return;
    
    setLoading(true);
    try {
      await axios.delete('/api/modules/capitalPrediction/bill', { 
        data: { id },
        withCredentials: true 
      });
      await fetchModuleData();
    } catch (err) {
      console.error('Error deleting bill:', err);
      setError('Failed to delete bill');
    } finally {
      setLoading(false);
    }
  }

  async function handleRunPrediction() {
    setLoading(true);
    try {
      await axios.post('/api/modules/capitalPrediction/predict', {}, { withCredentials: true });
      await fetchModuleData();
    } catch (err) {
      console.error('Error running prediction:', err);
      setError('Failed to run prediction');
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateSettings(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(
        '/api/modules/capitalPrediction/settings/currency', 
        {
          defaultCurrency: settingsForm.defaultCurrency,
          multiCurrency: {
            enabled: settingsForm.multiCurrencyEnabled,
            currencies: ['EUR', 'USD'],
            exchangeRates: {
              EUR: 0.20, // 1 RON = 0.20 EUR
              USD: 0.22  // 1 RON = 0.22 USD
            }
          }
        }, 
        { withCredentials: true }
      );
      setShowSettingsModal(false);
      await fetchModuleData();
    } catch (err) {
      console.error('Error updating settings:', err);
      setError('Failed to update settings');
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateTargetCapital() {
    setLoading(true);
    try {
      await axios.post(
        '/api/modules/capitalPrediction/target',
        { amount: parseFloat(targetCapital) },
        { withCredentials: true }
      );
      await fetchModuleData();
      calculateTargetDate();
    } catch (err) {
      console.error('Error updating target capital:', err);
      setError('Failed to update target capital');
    } finally {
      setLoading(false);
    }
  }

  // Helper function to format with current settings
  function formatWithSettings(amount) {
    if (!moduleData?.data?.settings) return formatCurrency(amount);
    
    const { defaultCurrency, multiCurrency } = moduleData.data.settings;
    
    if (!multiCurrency.enabled) {
      return formatCurrency(amount, defaultCurrency);
    }
    
    // If multi-currency is enabled, return the value in EUR
    return formatCurrency(amount, 'EUR', multiCurrency.exchangeRates);
  }

  // Calculate when the target capital will be reached
  function calculateTargetDate() {
    if (!targetCapital || !moduleData?.data?.predictionResults?.predictions || 
        moduleData.data.predictionResults.predictions.length === 0) {
      setTargetDates({ trendBased: null, incomeBased: null, meanBased: null });
      return;
    }
    
    const targetValue = parseFloat(targetCapital);
    if (isNaN(targetValue) || targetValue <= 0) {
      setTargetDates({ trendBased: null, incomeBased: null, meanBased: null });
      return;
    }
    
    const predictions = moduleData.data.predictionResults.predictions;
    const sortedPredictions = [...predictions].sort((a, b) => new Date(a.date) - new Date(b.date));
    
    // Calculate dates for both trend and income based predictions
    const calculateDateForValue = (predictionType) => {
      const lastPrediction = sortedPredictions[sortedPredictions.length - 1];
      const firstPrediction = sortedPredictions[0];
      const firstValue = firstPrediction[predictionType];
      const lastValue = lastPrediction[predictionType];
      
      // If already reached
      if (firstValue >= targetValue) {
        return "Already reached";
      }
      
      // If will never reach with current trajectory
      if (lastValue < targetValue) {
        const monthsDiff = (new Date(lastPrediction.date) - new Date(firstPrediction.date)) / (30 * 24 * 60 * 60 * 1000);
        const monthlyGrowth = (lastValue - firstValue) / monthsDiff;
        
        if (monthlyGrowth <= 0) {
          return "Never (no growth)";
        }
        
        // Calculate additional months needed
        const additionalAmount = targetValue - lastValue;
        const additionalMonths = additionalAmount / monthlyGrowth;
        
        const targetDateObj = new Date(lastPrediction.date);
        targetDateObj.setMonth(targetDateObj.getMonth() + Math.ceil(additionalMonths));
        return targetDateObj.toLocaleDateString();
      }
      
      // Target is within prediction range
      for (let i = 0; i < sortedPredictions.length - 1; i++) {
        const current = sortedPredictions[i];
        const next = sortedPredictions[i + 1];
        const currentValue = current[predictionType];
        const nextValue = next[predictionType];
        
        if (currentValue <= targetValue && nextValue >= targetValue) {
          const currentDate = new Date(current.date);
          const nextDate = new Date(next.date);
          const daysDiff = (nextDate - currentDate) / (24 * 60 * 60 * 1000);
          
          const portion = (targetValue - currentValue) / (nextValue - currentValue);
          const daysToAdd = Math.round(daysDiff * portion);
          
          const targetDateObj = new Date(currentDate);
          targetDateObj.setDate(targetDateObj.getDate() + daysToAdd);
          return targetDateObj.toLocaleDateString();
        }
      }
      
      return lastPrediction.date;
    };

    const trendDate = calculateDateForValue('trendAmount');
    const incomeDate = calculateDateForValue('incomeAmount');
    
    // Calculate mean date if both dates are actual dates
    let meanDate = null;
    if (trendDate && incomeDate && 
        !trendDate.includes('Never') && !trendDate.includes('Already') &&
        !incomeDate.includes('Never') && !incomeDate.includes('Already')) {
      const trendTimestamp = new Date(trendDate).getTime();
      const incomeTimestamp = new Date(incomeDate).getTime();
      const meanTimestamp = (trendTimestamp + incomeTimestamp) / 2;
      meanDate = new Date(meanTimestamp).toLocaleDateString();
    } else if (trendDate === incomeDate) {
      meanDate = trendDate;
    } else {
      meanDate = "Cannot calculate mean";
    }
    
    setTargetDates({
      trendBased: trendDate,
      incomeBased: incomeDate,
      meanBased: meanDate
    });
  }

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  if (!moduleData) {
    return (
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Capital Prediction Module</h2>
        <p className="mb-4">Track your capital growth and predict future trends.</p>
        <button
          onClick={installModule}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Install Module
        </button>
      </div>
    );
  }

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Capital Prediction Module</h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setShowSettingsModal(true)}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Settings
          </button>
          <button
            onClick={() => uninstallModule()}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Uninstall
          </button>
        </div>
      </div>

      <MonthlySummary moduleData={moduleData} formatWithSettings={formatWithSettings} />
      
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Capital Prediction</h3>
          <button
            onClick={handleRunPrediction}
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
          >
            Run Prediction
          </button>
        </div>
        
        {moduleData.data.predictionResults?.predictions && moduleData.data.predictionResults.predictions.length > 0 ? (
          <>
            <PredictionChart
              predictions={moduleData.data.predictionResults.predictions}
              historicalData={moduleData.data.capitalEvaluations}
              formatWithSettings={formatWithSettings}
            />
            
            {/* Target Capital Section */}
            <div className="mt-6 bg-gray-50 p-4 rounded-lg">
              <h4 className="text-md font-semibold mb-2">Target Capital Calculation</h4>
              <div className="flex items-end space-x-4">
                <div className="flex-grow">
                  <label className="block text-sm font-medium text-gray-700">Target Amount</label>
                  <input
                    type="number"
                    value={targetCapital}
                    onChange={(e) => setTargetCapital(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    placeholder="Enter target amount"
                  />
                </div>
                <button
                  onClick={() => {
                    handleUpdateTargetCapital();
                    calculateTargetDate();
                  }}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  disabled={!targetCapital}
                >
                  Save & Calculate
                </button>
              </div>
              {moduleData.data.targetCapital && (
                <div className="mt-3 space-y-2">
                  <div className="p-3 bg-blue-50 rounded">
                    <p className="text-blue-800 font-semibold mb-2">
                      Target: {formatWithSettings(moduleData.data.targetCapital)}
                    </p>
                    <div className="space-y-1 text-sm">
                      <p>
                        <span className="font-medium">Trend-based estimate:</span> {targetDates.trendBased || 'Calculating...'}
                      </p>
                      <p>
                        <span className="font-medium">Income-based estimate:</span> {targetDates.incomeBased || 'Calculating...'}
                      </p>
                      <p>
                        <span className="font-medium">Mean estimate:</span> {targetDates.meanBased || 'Calculating...'}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="text-center py-8 text-gray-500">
            No prediction data available. Click "Run Prediction" to generate predictions based on your data.
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Capital Evaluations */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Capital Evaluations</h3>
            <button
              onClick={() => setShowEvalModal(true)}
              className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
            >
              Add Evaluation
            </button>
          </div>
          <div className="space-y-3">
            {moduleData.data.capitalEvaluations.map(evaluation => (
              <div key={evaluation.id} className="bg-white p-3 rounded shadow">
                <div className="flex justify-between items-center">
                  <div>
                    <div className="font-semibold">{formatWithSettings(evaluation.amount)}</div>
                    <div className="text-sm text-gray-500">
                      {new Date(evaluation.date).toLocaleDateString()}
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => handleDeleteEvaluation(evaluation.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Income Sources */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Income Sources</h3>
            <button
              onClick={() => setShowIncomeModal(true)}
              className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
            >
              Add Income
            </button>
          </div>
          <div className="space-y-3">
            {moduleData.data.incomeSources.map(income => (
              <div key={income.id} className="bg-white p-3 rounded shadow">
                <div className="flex justify-between items-center">
                  <div>
                    <div className="font-semibold">{income.name}</div>
                    <div className="text-sm text-gray-500">
                      {formatWithSettings(income.amount)} ({income.frequency})
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => handleDeleteIncome(income.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Fixed Bills */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Fixed Bills</h3>
            <button
              onClick={() => setShowBillModal(true)}
              className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
            >
              Add Bill
            </button>
          </div>
          <div className="space-y-3">
            {moduleData.data.fixedBills.map(bill => (
              <div key={bill.id} className="bg-white p-3 rounded shadow">
                <div className="flex justify-between items-center">
                  <div>
                    <div className="font-semibold">{bill.name}</div>
                    <div className="text-sm text-gray-500">
                      {formatWithSettings(bill.amount)} ({bill.frequency})
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => handleDeleteBill(bill.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Budget */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Variable Expenses Budget</h3>
            <button
              onClick={() => setShowBudgetModal(true)}
              className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
            >
              Update Budget
            </button>
          </div>
          <div className="bg-white p-3 rounded shadow">
            <div className="font-semibold">Current Budget</div>
            <div className="text-sm text-gray-500">
              {moduleData.data.budget?.amount
                ? `${formatWithSettings(moduleData.data.budget.amount)} (${moduleData.data.budget.frequency})`
                : 'No budget set'}
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      <FormModal
        show={showEvalModal}
        title="Add Capital Evaluation"
        onClose={() => setShowEvalModal(false)}
      >
        <form onSubmit={handleAddEvaluation}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <input
                type="date"
                value={evalForm.date}
                onChange={(e) => setEvalForm(prev => ({ ...prev, date: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Amount</label>
              <input
                type="number"
                step="0.01"
                value={evalForm.amount}
                onChange={(e) => setEvalForm(prev => ({ ...prev, amount: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setShowEvalModal(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </FormModal>

      <FormModal
        show={showIncomeModal}
        title="Add Income Source"
        onClose={() => setShowIncomeModal(false)}
      >
        <form onSubmit={handleAddIncome}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                value={incomeForm.name}
                onChange={(e) => setIncomeForm(prev => ({ ...prev, name: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Amount</label>
              <input
                type="number"
                step="0.01"
                value={incomeForm.amount}
                onChange={(e) => setIncomeForm(prev => ({ ...prev, amount: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Frequency</label>
              <select
                value={incomeForm.frequency}
                onChange={(e) => setIncomeForm(prev => ({ ...prev, frequency: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Day of Month</label>
              <input
                type="number"
                min="1"
                max="31"
                value={incomeForm.dayOfMonth}
                onChange={(e) => setIncomeForm(prev => ({ ...prev, dayOfMonth: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setShowIncomeModal(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </FormModal>

      <FormModal
        show={showBillModal}
        title="Add Fixed Bill"
        onClose={() => setShowBillModal(false)}
      >
        <form onSubmit={handleAddBill}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                value={billForm.name}
                onChange={(e) => setBillForm(prev => ({ ...prev, name: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Amount</label>
              <input
                type="number"
                step="0.01"
                value={billForm.amount}
                onChange={(e) => setBillForm(prev => ({ ...prev, amount: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Frequency</label>
              <select
                value={billForm.frequency}
                onChange={(e) => setBillForm(prev => ({ ...prev, frequency: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Day of Month</label>
              <input
                type="number"
                min="1"
                max="31"
                value={billForm.dayOfMonth}
                onChange={(e) => setBillForm(prev => ({ ...prev, dayOfMonth: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setShowBillModal(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </FormModal>

      <FormModal
        show={showBudgetModal}
        title="Update Variable Expenses Budget"
        onClose={() => setShowBudgetModal(false)}
      >
        <form onSubmit={handleUpdateBudget}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Amount</label>
              <input
                type="number"
                step="0.01"
                value={budgetForm.amount}
                onChange={(e) => setBudgetForm(prev => ({ ...prev, amount: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Frequency</label>
              <select
                value={budgetForm.frequency}
                onChange={(e) => setBudgetForm(prev => ({ ...prev, frequency: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setShowBudgetModal(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </FormModal>

      {/* Settings Modal */}
      <FormModal
        show={showSettingsModal}
        title="Currency Settings"
        onClose={() => setShowSettingsModal(false)}
      >
        <form onSubmit={handleUpdateSettings}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Default Currency</label>
              <select
                value={settingsForm.defaultCurrency}
                onChange={(e) => setSettingsForm(prev => ({ ...prev, defaultCurrency: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="RON">RON (Romanian Leu)</option>
                <option value="EUR">EUR (Euro)</option>
                <option value="USD">USD (US Dollar)</option>
              </select>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="multiCurrency"
                checked={settingsForm.multiCurrencyEnabled}
                onChange={(e) => setSettingsForm(prev => ({ ...prev, multiCurrencyEnabled: e.target.checked }))}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="multiCurrency" className="ml-2 block text-sm text-gray-700">
                Display values in EUR instead of RON (using exchange rate)
              </label>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setShowSettingsModal(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </FormModal>
    </div>
  );
}

export default CapitalPredictionModulePage;