import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar } from 'recharts';
import { useError } from '../../contexts/ErrorContext';
import './DataAnalysisModule.css';

// Add this color palette array at the top of your file, after the imports
const DISTINCT_COLORS = [
  '#e41a1c', // red
  '#377eb8', // blue
  '#4daf4a', // green
  '#984ea3', // purple
  '#ff7f00', // orange
  '#ffff33', // yellow
  '#a65628', // brown
  '#f781bf', // pink
  '#999999', // grey
  '#8dd3c7', // mint
  '#bebada', // lavender
  '#fb8072', // salmon
  '#80b1d3', // light blue
  '#fdb462', // light orange
  '#b3de69', // lime
  '#fccde5', // rose
  '#d9d9d9', // light grey
  '#bc80bd', // orchid
  '#ccebc5', // pale green
  '#ffed6f'  // light yellow
];

function CorrelationTable({ analysisResults, moduleData, onUpdateModuleData }) {
  if (!analysisResults?.correlationMatrix) return null;

  const matrix = analysisResults.correlationMatrix;
  
  // Get all correlation pairs and sort by absolute correlation value
  const pairs = [];
  
  Object.keys(matrix).forEach(colA => {
    Object.keys(matrix[colA]).forEach(colB => {
      if (colA < colB) {
        const correlationKey = `${colA}:${colB}`;
        const value = matrix[colA][colB];
        // Only include medium or stronger correlations (|r| >= 0.3)
        if (Math.abs(value) < 0.3) return;
        
        const isIgnored = moduleData.data.ignored_correlations?.includes(correlationKey);
        if (isIgnored) return;

        pairs.push({
          colA,
          colB,
          correlationKey,
          value,
          isStarred: moduleData.data.starred_correlations?.includes(correlationKey),
          isFunny: moduleData.data.funny_correlations?.includes(correlationKey)
        });
      }
    });
  });

  // Sort pairs: starred first, then funny, then by absolute correlation value
  pairs.sort((a, b) => {
    if (a.isStarred && !b.isStarred) return -1;
    if (!a.isStarred && b.isStarred) return 1;
    if (a.isFunny && !b.isFunny) return -1;
    if (!a.isFunny && b.isFunny) return 1;
    return Math.abs(b.value) - Math.abs(a.value);
  });

  // Filter out weak correlations unless they're starred or funny
  const filteredPairs = pairs.filter(pair => 
    Math.abs(pair.value) >= 0.3 || pair.isStarred || pair.isFunny
  );

  async function handleToggleStarCorrelation(correlationKey, value) {
    const starred_correlations = moduleData.data.starred_correlations || [];
    const isCurrentlyStarred = starred_correlations.includes(correlationKey);
    
    // Update moduleData with new starred correlation and its value
    const updatedModuleData = {
      ...moduleData,
      data: {
        ...moduleData.data,
        starred_correlations: isCurrentlyStarred
          ? starred_correlations.filter(key => key !== correlationKey)
          : [...starred_correlations, correlationKey],
        correlation_values: {
          ...(moduleData.data.correlation_values || {}),
          [correlationKey]: value
        }
      }
    };

    onUpdateModuleData(updatedModuleData);
  }

  async function handleToggleFunnyCorrelation(correlationKey, value) {
    const funny_correlations = moduleData.data.funny_correlations || [];
    const isCurrentlyFunny = funny_correlations.includes(correlationKey);
    
    const updatedModuleData = {
      ...moduleData,
      data: {
        ...moduleData.data,
        funny_correlations: isCurrentlyFunny
          ? funny_correlations.filter(key => key !== correlationKey)
          : [...funny_correlations, correlationKey],
        correlation_values: {
          ...(moduleData.data.correlation_values || {}),
          [correlationKey]: value
        }
      }
    };

    onUpdateModuleData(updatedModuleData);
  }

  function getSignificance(value) {
    const absVal = Math.abs(value);
    if (absVal >= 0.7) return 'High';
    else if (absVal >= 0.3) return 'Moderate';
    else return 'Low';
  }

  async function handleIgnoreCorrelation(colA, colB) {
    // Create unique key for this correlation (always in same order)
    const correlationKey = colA < colB ? `${colA}:${colB}` : `${colB}:${colA}`;

    // Update moduleData with new ignored correlation
    const updatedModuleData = {
      ...moduleData,
      data: {
        ...moduleData.data,
        ignored_correlations: [...(moduleData.data.ignored_correlations || []), correlationKey]
      }
    };

    // Call parent handler to update the data
    onUpdateModuleData(updatedModuleData);
  }

  if (filteredPairs.length === 0) {
    return (
      <div className="correlation-table-container">
        <h3>Correlation Table</h3>
        <p>No correlations above ±0.3</p>
      </div>
    );
  }

  return (
    <div className="correlation-table-container">
      <h3>Correlation Table</h3>
      <table className="correlation-table">
        <thead>
          <tr>
            <th>Column A</th>
            <th>Column B</th>
            <th>Correlation</th>
            <th>Significance</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredPairs.map((pair, index) => (
            <tr
              key={index}
              className={`${pair.isStarred ? 'bg-yellow-50' : ''} ${pair.isFunny && !pair.isStarred ? 'bg-blue-50' : ''}`}
            >
              <td>{pair.colA}</td>
              <td>{pair.colB}</td>
              <td>{pair.value.toFixed(2)}</td>
              <td>{getSignificance(pair.value)}</td>
              <td>
                <div className="flex gap-2">
                  {/* Star button */}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleStarCorrelation(pair.correlationKey, pair.value);
                    }}
                    className={`transition-colors ${
                      pair.isStarred 
                        ? 'text-yellow-400 hover:text-yellow-500' 
                        : 'text-gray-400 hover:text-yellow-400'
                    }`}
                    title={pair.isStarred ? "Unstar correlation" : "Star correlation"}
                  >
                    <svg 
                      className="w-5 h-5" 
                      fill="currentColor" 
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </button>
                  {/* Funny button */}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleFunnyCorrelation(pair.correlationKey, pair.value);
                    }}
                    className={`transition-all ${
                      pair.isFunny 
                        ? 'text-blue-400 hover:text-blue-500' 
                        : 'text-gray-400 hover:text-blue-400'
                    }`}
                    style={{
                      filter: pair.isFunny ? 'none' : 'grayscale(100%)'
                    }}
                    title={pair.isFunny ? "Remove funny tag" : "Mark as funny"}
                  >
                    <span className="text-lg">😂</span>
                  </button>
                  {/* Delete button */}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleIgnoreCorrelation(pair.colA, pair.colB);
                    }}
                    className="text-gray-500 hover:text-red-500 transition-colors"
                    title="Ignore this correlation"
                  >
                    <svg 
                      className="w-5 h-5" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function SavedCorrelationsTable({ moduleData, onUpdateModuleData }) {
  if (!moduleData?.data?.starred_correlations?.length && !moduleData?.data?.funny_correlations?.length) {
    return null;
  }

  // Get all saved correlation keys and their values
  const savedKeys = new Set([
    ...(moduleData.data.starred_correlations || []),
    ...(moduleData.data.funny_correlations || [])
  ]);

  const savedCorrelations = Array.from(savedKeys).map(key => {
    const [colA, colB] = key.split(':');
    return {
      colA,
      colB,
      correlationKey: key,
      value: moduleData.data.correlation_values?.[key] || 0,
      isStarred: moduleData.data.starred_correlations?.includes(key),
      isFunny: moduleData.data.funny_correlations?.includes(key)
    };
  });

  async function handleRemoveCorrelation(correlationKey, type) {
    const arrayName = type === 'star' ? 'starred_correlations' : 'funny_correlations';
    const updatedArray = moduleData.data[arrayName].filter(key => key !== correlationKey);
    
    const updatedModuleData = {
      ...moduleData,
      data: {
        ...moduleData.data,
        [arrayName]: updatedArray
      }
    };

    onUpdateModuleData(updatedModuleData);
  }

  return (
    <div className="correlation-table-container mt-6">
      <h3 className="text-xl font-semibold mb-4">Saved Correlations</h3>
      <table className="correlation-table">
        <thead>
          <tr>
            <th>Column A</th>
            <th>Column B</th>
            <th>Correlation</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {savedCorrelations.map((corr, index) => (
            <tr key={index}>
              <td>{corr.colA}</td>
              <td>{corr.colB}</td>
              <td>{corr.value.toFixed(2)}</td>
              <td>
                {corr.isStarred && <span className="text-yellow-500 mr-2">⭐ Starred</span>}
                {corr.isFunny && <span className="text-blue-500">😂 Funny</span>}
              </td>
              <td>
                <div className="flex gap-2">
                  {corr.isStarred && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveCorrelation(corr.correlationKey, 'star');
                      }}
                      className="text-gray-500 hover:text-red-500"
                      title="Remove from starred"
                    >
                      Remove Star
                    </button>
                  )}
                  {corr.isFunny && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveCorrelation(corr.correlationKey, 'funny');
                      }}
                      className="text-gray-500 hover:text-red-500"
                      title="Remove from funny"
                    >
                      Remove Funny
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function DataGeneratorCard({
  generator,
  matches = [],
  onEdit,
  onDelete,
  onToggleStar,
  analysisResults,
  isEditing,
  startEditing,
  editState,
  setEditState,
  updateGenerator,
  cancelEditing
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Check if this generator appears in analysis results
  const isInAnalysis = analysisResults?.generators?.includes(generator.name);
  
  // Determine star color based on both starred status and analysis presence
  const starColor = generator.isStarred 
    ? (isInAnalysis ? 'text-green-500 hover:text-green-600' : 'text-yellow-400 hover:text-yellow-500')
    : 'text-gray-400 hover:text-yellow-400';

  // Group matches by date for this generator
  const matchesByDate = matches.reduce((acc, match) => {
    const date = match.date;
    if (!acc[date]) acc[date] = [];
    acc[date].push(match);
    return acc;
  }, {});

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4">
      {isEditing ? (
        // Edit mode UI
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={editState.name}
              onChange={(e) => setEditState(prev => ({ ...prev, name: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Regex Pattern</label>
            <input
              type="text"
              value={editState.regex}
              onChange={(e) => setEditState(prev => ({ ...prev, regex: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Value Extractor (leave empty to record #exists)</label>
            <input
              type="text"
              value={editState.valueExtractor}
              onChange={(e) => setEditState(prev => ({ ...prev, valueExtractor: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={editState.noDefaultValue}
                onChange={(e) => setEditState(prev => ({ ...prev, noDefaultValue: e.target.checked }))}
                className="form-checkbox h-4 w-4 text-indigo-600"
              />
              <span className="text-sm text-gray-700">Lack of data is not default value (don't show points with no data)</span>
            </label>
          </div>
          <div className="flex justify-end gap-2">
            <button
              onClick={cancelEditing}
              className="px-3 py-1 text-sm bg-gray-200 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={() => updateGenerator(generator.id)}
              className="px-3 py-1 text-sm bg-indigo-500 text-white rounded hover:bg-indigo-600"
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        // Normal display mode UI
        <>
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold">{generator.name}</h3>
            <div className="flex gap-2">
              <button
                onClick={() => startEditing(generator)}
                className="text-blue-500 hover:text-blue-700"
              >
                Edit
              </button>
              <button
                onClick={() => onToggleStar(generator)}
                className={`transition-colors ${starColor}`}
                title={generator.isStarred ? "Unstar generator" : "Star generator"}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </button>
              <button
                onClick={() => onDelete(generator.id)}
                className="text-gray-500 hover:text-red-500"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-gray-500 hover:text-gray-700"
              >
                {isExpanded ? '▼' : '▶'}
              </button>
            </div>
          </div>

          <div className="text-sm text-gray-600 mb-2">
            <div>ID: {generator.id}</div>
            <div>Regex: {generator.regex}</div>
            <div>Value Extractor: {generator.valueExtractor}</div>
          </div>

          {isExpanded && (
            <div className="mt-4 border-t pt-4">
              <h4 className="font-medium mb-2">Recent Matches</h4>
              {/* Matches scrollbox */}
              {analysisResults?.rawMatches?.filter(match => match.generator === generator.name).length > 0 ? (
                <div className="mt-4">
                  <h5 className="text-sm font-medium text-gray-700 mb-2">Matches Found:</h5>
                  <div className="matches-scrollbox">
                    {analysisResults.rawMatches
                      .filter(match => match.generator === generator.name)
                      .map((match, idx) => (
                        <div key={idx} className="match-item">
                          <span className="text-gray-500">
                            {new Date(match.timestamp).toLocaleDateString()}
                          </span>
                          <span className="mx-2">-</span>
                          <span className="text-gray-700">{match.eventSummary}</span>
                          {match.value && (
                            <span className="float-right text-blue-600">
                              Value: {match.value}
                            </span>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="text-gray-500 italic mt-2">No matches found</div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

function DataAnalysisModulePage() {
  const { setError } = useError();
  const [moduleData, setModuleData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generators, setGenerators] = useState([]);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [newGeneratorName, setNewGeneratorName] = useState('');
  const [newGeneratorRegex, setNewGeneratorRegex] = useState('');
  const [newGeneratorValueExtractor, setNewGeneratorValueExtractor] = useState('');
  const [newGeneratorNoDefaultValue, setNewGeneratorNoDefaultValue] = useState(false);

  const [selectedGenerator, setSelectedGenerator] = useState(null);
  const [timeRange, setTimeRange] = useState('365'); // Changed from 30 to 365 days

  const [editingGenerator, setEditingGenerator] = useState(null);
  const [editState, setEditState] = useState({
    name: '',
    regex: '',
    valueExtractor: '',
    ignoreCorrelations: false,
    noDefaultValue: false
  });

  const [timeAggregation, setTimeAggregation] = useState('month'); // 'year', 'month', or 'week'

  const [hiddenGenerators, setHiddenGenerators] = useState(() => {
    try {
      const saved = localStorage.getItem('hiddenGenerators');
      return saved ? new Set(JSON.parse(saved)) : new Set();
    } catch (e) {
      return new Set();
    }
  });

  const [useLogScale, setUseLogScale] = useState(false);
  const [chartHeight, setChartHeight] = useState(450); // Increased default height

  const networkRef = useRef();

  // Group matches by generator
  const matchesByGenerator = React.useMemo(() => {
    if (!analysisResults?.rawMatches) return {};

    return analysisResults.rawMatches.reduce((acc, match) => {
      if (!acc[match.generator]) acc[match.generator] = [];
      acc[match.generator].push(match);
      return acc;
    }, {});
  }, [analysisResults?.rawMatches]);

  useEffect(() => {
    fetchModuleData();
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem('hiddenGenerators', JSON.stringify([...hiddenGenerators]));
    } catch (e) {
      console.error('Failed to save hidden generators to localStorage:', e);
    }
  }, [hiddenGenerators]);

  async function fetchModuleData() {
    setLoading(true);
    try {
      const res = await axios.get('/api/objects', { withCredentials: true });
      const objects = res.data.objects;
      const found = objects.find(obj => obj.type === 'ModuleData' && obj.name === 'DataAnalysisModule');
      if (found) {
        setModuleData(found);
        setGenerators(found.data.dataGenerators || []);
        setAnalysisResults(found.data.analysisResults || null);
      } else {
        setModuleData(null);
      }
    } catch (err) {
      console.error('Error fetching module data:', err);
    } finally {
      setLoading(false);
    }
  }

  async function installModule() {
    try {
      setLoading(true);
      await axios.post('/api/modules/dataAnalysis/install', {}, { withCredentials: true });
      await fetchModuleData();
    } catch (err) {
      console.error('Error installing Data Analysis module:', err);
    } finally {
      setLoading(false);
    }
  }

  async function uninstallModule(keepData) {
    try {
      setLoading(true);
      await axios.post(
        '/api/modules/dataAnalysis/uninstall',
        { keepData },
        { withCredentials: true }
      );
      setModuleData(null);
      setGenerators([]);
      setAnalysisResults(null);
    } catch (err) {
      console.error('Error uninstalling Data Analysis module:', err);
    } finally {
      setLoading(false);
    }
  }

  async function runAnalysis() {
    try {
      setLoading(true);
      const days = parseInt(timeRange, 10);
      if (isNaN(days) || days < 1) {
        setError('Please enter a valid number of days bigger than 1');
        return;
      }
      
      // Set a minimum time range to ensure we get historical data
      const effectiveDays = Math.max(days, 365); // At least 1 year of data
      
      const endDate = new Date().toISOString();
      const startDate = new Date(Date.now() - effectiveDays * 24 * 60 * 60 * 1000).toISOString();
      
      console.log(`Fetching data from ${startDate} to ${endDate} (${effectiveDays} days)`);

      const res = await axios.post('/api/modules/dataAnalysis/runAnalysis',
        { startDate, endDate },
        { withCredentials: true }
      );
      if (res.data.analysisResults) {
        console.log(`Received ${res.data.analysisResults.rawMatches?.length || 0} matches`);
        setAnalysisResults(res.data.analysisResults);
      }
    } catch (err) {
      console.error('Error running data analysis:', err);
      setError('Failed to run analysis: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  }

  async function addDataGenerator() {
    if (!moduleData) return;

    const valueExtractor = newGeneratorValueExtractor.trim() || '#exists';

    const newGen = {
      id: Math.random().toString(36).substr(2, 9),
      name: newGeneratorName.trim(),
      regex: newGeneratorRegex.trim(),
      valueExtractor,
      noDefaultValue: newGeneratorNoDefaultValue
    };

    try {
      setLoading(true);
      const updatedGenerators = [...generators, newGen];

      const updatedModuleData = {
        ...moduleData,
        data: {
          ...moduleData.data,
          dataGenerators: updatedGenerators
        }
      };

      await axios.put(`/api/objects/${moduleData.id}`, updatedModuleData, { withCredentials: true });
      setGenerators(updatedGenerators);

      setNewGeneratorName('');
      setNewGeneratorRegex('');
      setNewGeneratorValueExtractor('');
      setNewGeneratorNoDefaultValue(false);
    } catch (err) {
      console.error('Error adding data generator:', err);
    } finally {
      setLoading(false);
    }
  }

  async function updateGenerator(genId) {
    if (!moduleData) return;

    const updatedGenerators = generators.map(gen => {
      if (gen.id === genId) {
        return {
          ...gen,
          ...editState
        };
      }
      return gen;
    });

    try {
      setLoading(true);
      const updatedModuleData = {
        ...moduleData,
        data: {
          ...moduleData.data,
          dataGenerators: updatedGenerators
        }
      };

      await axios.put(`/api/objects/${moduleData.id}`, updatedModuleData, { withCredentials: true });
      setModuleData(updatedModuleData);
      setGenerators(updatedGenerators);
      setEditingGenerator(null);
      setEditState({ name: '', regex: '', valueExtractor: '', ignoreCorrelations: false, noDefaultValue: false });
    } catch (err) {
      console.error('Error updating generator:', err);
      setError('Failed to update generator: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  }

  const startEditing = (generator) => {
    setEditingGenerator(generator.id);
    setEditState({
      name: generator.name,
      regex: generator.regex,
      valueExtractor: generator.valueExtractor,
      ignoreCorrelations: generator.ignoreCorrelations || false,
      noDefaultValue: generator.noDefaultValue || false
    });
  };

  const resetEditState = () => {
    setEditingGenerator(null);
    setEditState({
      name: '',
      regex: '',
      valueExtractor: '',
      ignoreCorrelations: false,
      noDefaultValue: false
    });
  };

  const renderDataVisualization = () => {
    if (!analysisResults?.dataEntries || analysisResults.dataEntries.length === 0) {
      return null;
    }

    const filteredEntries = analysisResults.dataEntries
      .filter(entry => entry.generatorName === selectedGenerator)
      .map(entry => ({
        timestamp: new Date(entry.timestamp),
        value: entry.value === '#exists' ? 1 : Number(entry.value) || 0
      }))
      .sort((a, b) => a.timestamp - b.timestamp);

    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        <LineChart width={600} height={300} data={filteredEntries}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="timestamp"
            tickFormatter={(date) => new Date(date).toLocaleDateString()}
          />
          <YAxis />
          <Tooltip
            labelFormatter={(date) => new Date(date).toLocaleString()}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            name={selectedGenerator}
          />
        </LineChart>
      </div>
    );
  };

  function renderMultiLineChart() {
    if (!analysisResults?.dateArray || analysisResults.dateArray.length === 0) {
      return null;
    }

    // We'll reformat dateArray for Recharts directly
    // e.g. each object: { date: '2023-10-02', Gaming: 3, Mood: 5, ... }
    const data = analysisResults.dateArray.map(item => ({
      ...item,
      // Convert date string to something more readable or keep as is
      date: item.date
    }));

    // Each generatorName should be a separate <Line />
    return (
      <LineChart width={700} height={350} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={dateStr => new Date(dateStr).toLocaleDateString()}
          angle={-15}
          tickMargin={10}
        />
        <YAxis />
        <Tooltip />
        <Legend />
        {analysisResults.generators?.map((genName) => (
          <Line
            key={genName}
            type="monotone"
            dataKey={genName}
            name={genName}
            stroke={'#' + Math.floor(Math.random() * 16777215).toString(16)} // random color
            strokeWidth={2}
            dot={false}
          />
        ))}
      </LineChart>
    );
  }

  function renderAggregatedLineChart() {
    if (!analysisResults?.rawMatches || analysisResults.rawMatches.length === 0 || !analysisResults?.generators) {
      console.log("No results to render chart:", analysisResults);
      return null;
    }

    console.log("Raw matches:", analysisResults.rawMatches);
    console.log("Generators:", analysisResults.generators);
    
    // Get unique generator names from the analysis results
    const generators = analysisResults.generators || [];
    
    // Group data by time period and generator
    const aggregatedData = {};
    
    // Debug flag to check data for each match
    let foundNonZeroValues = false;
    
    // First, process any matches that are already time series aggregates
    const timeSeriesMatches = analysisResults.rawMatches.filter(m => m.isTimeSeriesAggregate);
    const detailMatches = analysisResults.rawMatches.filter(m => !m.isTimeSeriesAggregate);
    
    console.log(`Found ${timeSeriesMatches.length} time series aggregates and ${detailMatches.length} detail matches`);
    
    // Process pre-aggregated time series data (if available)
    timeSeriesMatches.forEach(match => {
      if (!match.timestamp) return;
      
      const date = new Date(match.timestamp);
      let timePeriod;
      
      // Format time period based on selected aggregation
      if (timeAggregation === 'year') {
        timePeriod = date.getFullYear().toString();
      } else if (timeAggregation === 'month') {
        timePeriod = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      } else if (timeAggregation === 'week') {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const daysSinceFirstDay = Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000));
        const weekNumber = Math.ceil((daysSinceFirstDay + firstDayOfYear.getDay() + 1) / 7);
        timePeriod = `${date.getFullYear()}-W${weekNumber.toString().padStart(2, '0')}`;
      } else if (timeAggregation === 'day') {
        timePeriod = date.toISOString().split('T')[0];
      }
      
      if (!aggregatedData[timePeriod]) {
        aggregatedData[timePeriod] = {};
        generators.forEach(gen => {
          aggregatedData[timePeriod][gen] = {
            count: 0,
            sum: 0
          };
        });
      }
      
      // If this is a time series aggregate, use its value directly
      if (match.isTimeSeriesAggregate && match.generator && generators.includes(match.generator)) {
        // If it's marked as an average, use the value directly
        // Otherwise, maintain backward compatibility with older data
        aggregatedData[timePeriod][match.generator].sum = match.isAverage ? match.value * match.count : match.value || 0;
        aggregatedData[timePeriod][match.generator].count = match.count || 1;
        aggregatedData[timePeriod][match.generator].isAverage = match.isAverage;
        
        if (match.value > 0) {
          foundNonZeroValues = true;
        }
      }
    });
    
    // Then process detailed matches if we got any
    detailMatches.forEach(match => {
      // Same processing as before
      if (!match.timestamp) return;
      
      const date = new Date(match.timestamp);
      let timePeriod;
      
      // Format time period based on selected aggregation
      if (timeAggregation === 'year') {
        timePeriod = date.getFullYear().toString();
      } else if (timeAggregation === 'month') {
        timePeriod = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      } else if (timeAggregation === 'week') {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const daysSinceFirstDay = Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000));
        const weekNumber = Math.ceil((daysSinceFirstDay + firstDayOfYear.getDay() + 1) / 7);
        timePeriod = `${date.getFullYear()}-W${weekNumber.toString().padStart(2, '0')}`;
      } else if (timeAggregation === 'day') {
        timePeriod = date.toISOString().split('T')[0];
      }
      
      if (!aggregatedData[timePeriod]) {
        aggregatedData[timePeriod] = {};
        generators.forEach(gen => {
          aggregatedData[timePeriod][gen] = {
            count: 0,
            sum: 0
          };
        });
      }
      
      // Process value as before
      const generatorName = match.generator;
      if (!generators.includes(generatorName)) return;
      
      let numericValue;
      if (match.value === '#exists') {
        numericValue = 1;
      } else if (typeof match.value === 'number') {
        numericValue = match.value;
      } else if (typeof match.value === 'string' && !isNaN(parseFloat(match.value))) {
        numericValue = parseFloat(match.value);
      } else {
        numericValue = 1; // Default to 1 for other cases
      }
      
      if (numericValue !== 0) {
        foundNonZeroValues = true;
      }
      
      aggregatedData[timePeriod][generatorName].count += 1;
      aggregatedData[timePeriod][generatorName].sum += numericValue;
    });
    
    // Rest of function remains the same
    console.log("Aggregated data:", aggregatedData);
    console.log("Found non-zero values:", foundNonZeroValues);
    
    // Convert to array format for Recharts
    const chartData = Object.keys(aggregatedData)
      .sort()
      .map(period => {
        const dataPoint = { timePeriod: period };
        generators.forEach(gen => {
          const genData = aggregatedData[period][gen];
          const generatorConfig = moduleData.data.dataGenerators.find(g => g.name === gen);
          
          // Skip if no data and generator is configured to not show default values
          if ((!genData || genData.count === 0) && generatorConfig?.noDefaultValue) {
            dataPoint[gen] = null; // Using null instead of 0 tells recharts not to draw this point
            return;
          }
          
          // Only use average for data points marked as averages
          // Otherwise use sum (for #exists and other types)
          dataPoint[gen] = genData.isAverage && genData.count > 0
            ? genData.sum / genData.count  // average for numeric data
            : genData.sum;                 // sum for #exists and others
        });
        return dataPoint;
      });

    if (chartData.length === 0) {
      return <div className="text-gray-500 italic mt-4">No time-based data available for aggregation</div>;
    }

    // Format the time period for display
    const formatTimePeriod = (period) => {
      if (timeAggregation === 'year') {
        return period;
      } else if (timeAggregation === 'month') {
        const [year, month] = period.split('-');
        return new Date(year, month - 1).toLocaleDateString(undefined, { year: 'numeric', month: 'short' });
      } else if (timeAggregation === 'week') {
        const [year, week] = period.split('-W');
        return `${year} Week ${week}`;
      } else if (timeAggregation === 'day') {
        return new Date(period).toLocaleDateString();
      }
      return period;
    };

    // Generate deterministic colors for each generator
    const getGeneratorColor = (generator, index) => {
      // Use the predefined colors first
      if (index < DISTINCT_COLORS.length) {
        return DISTINCT_COLORS[index];
      }
      
      // If we run out of predefined colors, generate one deterministically
      const hash = generators.indexOf(generator) * 4831 + 8327;
      const hue = (hash % 360); // Use full hue range
      const saturation = 70 + (hash % 20); // 70-90%
      const lightness = 45 + (hash % 10); // 45-55%
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    // Calculate proper width with right padding to avoid scrollbar issues
    const minWidth = 900; // Increased from 700
    const widthPerDataPoint = 60; // Increased from 50
    const chartWidth = Math.max(minWidth, chartData.length * widthPerDataPoint);
    const rightPadding = 120; // Increased padding
    const containerWidth = chartWidth + rightPadding;

    // Add this function to handle visibility toggle
    const handleGeneratorClick = (generator) => {
      setHiddenGenerators(prev => {
        const newHidden = new Set(prev);
        if (newHidden.has(generator)) {
          newHidden.delete(generator);
        } else {
          newHidden.add(generator);
        }
        return newHidden;
      });
    };

    return (
      <div className="bg-white p-4 rounded-lg shadow-md mt-6">
        <h3 className="text-lg font-semibold mb-4">Data Trends by {timeAggregation.charAt(0).toUpperCase() + timeAggregation.slice(1)}</h3>
        
        <div className="mb-4">
          <div className="flex justify-between mb-4">
          <div className="flex space-x-4">
            <button
              onClick={() => setTimeAggregation('year')}
              className={`px-3 py-1 rounded ${timeAggregation === 'year' ? 'bg-indigo-500 text-white' : 'bg-gray-200'}`}
            >
              Yearly
            </button>
            <button
              onClick={() => setTimeAggregation('month')}
              className={`px-3 py-1 rounded ${timeAggregation === 'month' ? 'bg-indigo-500 text-white' : 'bg-gray-200'}`}
            >
              Monthly
            </button>
            <button
              onClick={() => setTimeAggregation('week')}
              className={`px-3 py-1 rounded ${timeAggregation === 'week' ? 'bg-indigo-500 text-white' : 'bg-gray-200'}`}
            >
              Weekly
            </button>
            <button
              onClick={() => setTimeAggregation('day')}
              className={`px-3 py-1 rounded ${timeAggregation === 'day' ? 'bg-indigo-500 text-white' : 'bg-gray-200'}`}
            >
              Daily
            </button>
          </div>
            <div className="flex space-x-4">
        <button
                onClick={() => setUseLogScale(!useLogScale)}
                className={`px-3 py-1 rounded ${useLogScale ? 'bg-purple-500 text-white' : 'bg-gray-200'}`}
        >
                {useLogScale ? 'Linear Scale' : 'Log Scale'}
        </button>
              <div className="flex items-center space-x-2">
          <button
                  onClick={() => setChartHeight(chartHeight - 50)}
                  className="px-2 py-1 bg-gray-200 rounded hover:bg-gray-300"
                  disabled={chartHeight <= 350}
                >
                  -
                </button>
                <span>Size</span>
                <button
                  onClick={() => setChartHeight(chartHeight + 50)}
                  className="px-2 py-1 bg-gray-200 rounded hover:bg-gray-300"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
        
        {/* Updated container styling */}
        <div className="relative">
          <div 
            className="overflow-x-auto"
            style={{ 
              maxWidth: '100%',
              // Add padding to prevent scrollbar from covering content
              paddingBottom: '20px',
              // Ensure scrollbar appears below the chart
              marginBottom: '-20px'
            }}
          >
            <div style={{ width: containerWidth, paddingRight: rightPadding }}>
              <LineChart 
                width={chartWidth} 
                height={chartHeight} // Use dynamic height instead of fixed 350
                data={chartData} 
                margin={{ right: rightPadding, bottom: 20, top: 20, left: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="timePeriod" 
                  tickFormatter={formatTimePeriod}
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={0}
                />
                <YAxis 
                  scale={useLogScale ? 'log' : 'auto'}
                  domain={useLogScale ? ['auto', 'auto'] : [0, 'auto']}
                  allowDataOverflow={true}
                  padding={{ top: 20 }}
                  tickFormatter={(value) => value.toLocaleString()}
                />
                <Tooltip 
                  formatter={(value, name, props) => {
                    // Find the original data for this point
                    const periodData = aggregatedData[props.payload.timePeriod];
                    const genData = periodData && periodData[name];
                    
                    if (genData) {
                      return [
                        genData.isAverage 
                          ? `${parseFloat(value).toFixed(2)} (avg of ${genData.count} entries)`
                          : `${parseFloat(value).toFixed(2)} (sum of ${genData.count} entries)`,
                        name
                      ];
                    }
                    return [parseFloat(value).toFixed(2), name];
                  }}
                  labelFormatter={formatTimePeriod}
                  offset={20}
                />
                <Legend 
                  verticalAlign="bottom" 
                  height={36}
                  wrapperStyle={{ 
                    paddingTop: '10px',
                    paddingBottom: '20px', // Add padding below legend
                    marginBottom: '-20px'  // Compensate for padding
                  }}
                  onClick={(e) => handleGeneratorClick(e.dataKey)}
                />
                {generators.map((gen, index) => (
                  !hiddenGenerators.has(gen) && (
                    <Line 
                      key={gen} 
                      type="monotone"
                      dataKey={gen} 
                      name={gen}
                      stroke={getGeneratorColor(gen, index)}
                      strokeWidth={3} // Increased from 2
                      dot={true}
                      activeDot={{ r: 7 }} // Increased from 6
                    />
                  )
                ))}
              </LineChart>
            </div>
          </div>
        </div>
        
        {/* Add custom legend with checkboxes for better control */}
        <div className="mt-4 flex flex-wrap gap-4">
          {generators.map((gen, index) => (
            <label key={gen} className="flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                checked={!hiddenGenerators.has(gen)}
                onChange={() => handleGeneratorClick(gen)}
                className="form-checkbox h-4 w-4 text-indigo-600"
              />
              <span className="flex items-center gap-2">
                <span 
                  className="inline-block w-4 h-4" 
                  style={{ backgroundColor: getGeneratorColor(gen, index) }}
                />
                {gen}
              </span>
            </label>
          ))}
        </div>
        
        {/* Debug information */}
        {!foundNonZeroValues && (
          <div className="mt-4 p-3 bg-yellow-100 border border-yellow-300 rounded-md">
            <p className="font-medium text-yellow-800">Debug Info:</p>
            <p className="text-sm text-yellow-700">
              All values are zero. Possible issues:
              <ul className="list-disc pl-5 mt-1">
                <li>Limited matches from server (only 10 per generator)</li>
                <li>Value extraction not working correctly</li>
                <li>Data generators might need updating</li>
              </ul>
            </p>
          </div>
        )}
      </div>
    );
  }

  async function handleUninstall(keepData = false) {
    try {
      setLoading(true);
      await axios.post(
        '/api/modules/dataAnalysis/uninstall',
        { keepData },
        { withCredentials: true }
      );
      setModuleData(null);
      setShowModal(false);
    } catch (error) {
      console.error('Error uninstalling Data Analysis module:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateModuleData(updatedData) {
    try {
      setLoading(true);
      await axios.put(`/api/objects/${moduleData.id}`, updatedData, { withCredentials: true });
      setModuleData(updatedData);
    } catch (err) {
      console.error('Error updating module data:', err);
      setError('Failed to update module data: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  }

  const handleToggleStar = async (generator) => {
    try {
      const updatedGenerators = moduleData.data.dataGenerators.map(g =>
        g.id === generator.id
          ? { ...g, isStarred: !g.isStarred }
          : g
      );

      const updatedModuleData = {
        ...moduleData,
        data: {
          ...moduleData.data,
          dataGenerators: updatedGenerators
        }
      };

      await handleUpdateModuleData(updatedModuleData);
    } catch (error) {
      setError('Failed to update generator star status');
    }
  };

  const handleDeleteGenerator = async (generatorId) => {
    try {
      const updatedGenerators = moduleData.data.dataGenerators.filter(
        g => g.id !== generatorId
      );

      const updatedModuleData = {
        ...moduleData,
        data: {
          ...moduleData.data,
          dataGenerators: updatedGenerators
        }
      };

      await handleUpdateModuleData(updatedModuleData);
    } catch (error) {
      setError('Failed to delete generator');
    }
  };

  if (loading) {
    return <div>Loading Data Analysis Module...</div>;
  }

  if (!moduleData) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Data Analysis Module</h2>
        <p className="mb-6 text-gray-600">
          Create custom data trackers from your calendar events and discover patterns in your daily life.
        </p>
        <button
          onClick={installModule}
          className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg 
                   hover:from-blue-600 hover:to-indigo-600 transition-all duration-200 shadow-md"
        >
          Install Data Analysis Module
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {!moduleData ? (
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Data Analysis Module</h2>
          <p className="mb-6 text-gray-600">
            Create custom data trackers from your calendar events and discover patterns in your daily life.
          </p>
          <button
            onClick={installModule}
            className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg 
                     hover:from-blue-600 hover:to-indigo-600 transition-all duration-200 shadow-md"
          >
            Install Data Analysis Module
          </button>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-800">Data Analysis Module</h2>
            <button
              onClick={() => setShowModal(true)}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Uninstall
            </button>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">Data Generators</h3>

            <div className="grid grid-cols-2 gap-4 mb-6 p-4 bg-gray-50 rounded-lg">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    value={newGeneratorName}
                    onChange={(e) => setNewGeneratorName(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="e.g., Gaming Time"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Regex Pattern</label>
                  <input
                    type="text"
                    value={newGeneratorRegex}
                    onChange={(e) => setNewGeneratorRegex(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="e.g., ^Played"
                  />
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Value Extractor (leave empty to record #exists)</label>
                  <input
                    type="text"
                    value={newGeneratorValueExtractor}
                    onChange={(e) => setNewGeneratorValueExtractor(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="e.g., $1 or #time"
                  />
                </div>
                <div className="col-span-2">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={newGeneratorNoDefaultValue}
                      onChange={(e) => setNewGeneratorNoDefaultValue(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <span className="text-sm text-gray-700">Lack of data is not default value (don't show points with no data)</span>
                  </label>
                </div>
              </div>
              <button
                onClick={addDataGenerator}
                className="col-span-2 bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600"
              >
                Add Generator
              </button>
            </div>

            <div className="space-y-4">
              {generators.map((gen) => (
                <DataGeneratorCard
                  key={gen.id}
                  generator={gen}
                  matches={matchesByGenerator[gen.name] || []}
                  onEdit={startEditing}
                  onDelete={handleDeleteGenerator}
                  onToggleStar={handleToggleStar}
                  analysisResults={analysisResults}
                  isEditing={editingGenerator === gen.id}
                  startEditing={startEditing}
                  editState={editState}
                  setEditState={setEditState}
                  updateGenerator={updateGenerator}
                  cancelEditing={resetEditState}
                />
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold">Analysis</h3>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <label className="text-sm text-gray-600">Last</label>
                  <input
                    type="number"
                    min="1"
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value)}
                    className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                  <span className="text-sm text-gray-600">days</span>
                </div>
                <button
                  onClick={runAnalysis}
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                >
                  Run Analysis
                </button>
              </div>
            </div>

            {/* Multi-line chart for all generators, by date */}
            {renderMultiLineChart()}

            {renderAggregatedLineChart()}
          </div>

          {/* Render the correlation table instead of the D3 network */}
          <>
            <CorrelationTable
              analysisResults={analysisResults}
              moduleData={moduleData}
              onUpdateModuleData={handleUpdateModuleData}
            />
            <SavedCorrelationsTable
              moduleData={moduleData}
              onUpdateModuleData={handleUpdateModuleData}
            />
          </>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-md">
            <h3 className="text-xl font-bold mb-4">Uninstall Module</h3>
            <p className="mb-6">Do you want to keep your data when uninstalling?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => handleUninstall(true)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Keep Data
              </button>
              <button
                onClick={() => handleUninstall(false)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Remove Everything
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DataAnalysisModulePage; 