import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

function PhotoSelector({ isOpen, onClose, onSelect, defaultDate }) {
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedDate, setSelectedDate] = useState(defaultDate || new Date());

  useEffect(() => {
    if (isOpen) {
      fetchPhotos(selectedDate);
    }
  }, [isOpen, selectedDate]);

  const fetchPhotos = async (date) => {
    try {
      const response = await axios.get('/api/photos', {
        params: {
          date: date.toISOString()
        },
        withCredentials: true
      });
      setPhotos(response.data);
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };

  const handlePhotoToggle = (photo) => {
    setSelectedPhotos(prev => {
      if (prev.find(p => p.id === photo.id)) {
        return prev.filter(p => p.id !== photo.id);
      }
      return [...prev, photo];
    });
  };

  const handleSave = () => {
    onSelect(selectedPhotos);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="max-w-4xl mx-auto mt-20 p-6 bg-white rounded-lg"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Select Photos</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">✕</button>
        </div>

        <div className="mb-4">
          <input
            type="date"
            value={selectedDate.toISOString().split('T')[0]}
            onChange={(e) => setSelectedDate(new Date(e.target.value))}
            className="border rounded p-2"
          />
        </div>

        <div className="grid grid-cols-4 gap-4 max-h-96 overflow-y-auto">
          {photos.map((photo) => (
            <div
              key={photo.id}
              className={`relative cursor-pointer ${
                selectedPhotos.find(p => p.id === photo.id) ? 'ring-2 ring-blue-500' : ''
              }`}
              onClick={() => handlePhotoToggle(photo)}
            >
              <img
                src={photo.baseUrl}
                alt={photo.filename}
                className="w-full h-32 object-cover rounded"
              />
            </div>
          ))}
        </div>

        <div className="flex justify-end space-x-2">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Link Selected Photos
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default PhotoSelector;