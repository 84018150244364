// client/src/pages/ModulesPage.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';
import { usePageTitle } from '../hooks/usePageTitle';
import { useObjects } from '../contexts/ObjectsContext';

function ModulesPage() {
  const [availableModules] = useState([
    {
      name: 'LifeAreasModule',
      path: 'life-areas',
      description: 'Track and improve different areas of your life with AI Watchers'
    },
    {
      name: 'ClosePeopleModule',
      path: 'close-people',
      description: 'Track and improve your relationships with people close to you'
    },
    {
      name: 'GratitudeJarModule',
      path: 'gratitude-jar',
      description: 'Write a quick weekly gratitude note, saved in a jar'
    },
    {
      name: 'AutoDiaryModule',
      path: 'auto-diary',
      description: 'Add your day\'s events to the calendar via AI'
    },
    {
      name: 'DataAnalysisModule',
      path: 'data-analysis',
      description: 'Analyze your data'
    },
    {
      name: 'DailyCompanionModule',
      path: 'daily-companion',
      description: 'Analyze your daily data and generate a report'
    },
    {
      name: 'CapitalPredictionModule',
      path: 'capital-prediction',
      description: 'Track your finances and predict future capital growth'
    }
  ]);
  const [activeModules, setActiveModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showError } = useError();
  const { objects, getObjects, isLoading: isLoadingObjects } = useObjects();

  usePageTitle('Modules');

  const loadActiveModules = async () => {
    try {
      const moduleData = objects.filter(obj => obj.type === 'ModuleData');
      const activeModuleNames = moduleData.map(md => md.name);
      setActiveModules(activeModuleNames);
    } catch (error) {
      console.error('Error loading active modules:', error);
      showError('Error loading active modules');
    }
    setLoading(false);
  };

  useEffect(() => {
    getObjects();
  }, []);

  useEffect(() => {
    loadActiveModules();
  }, [objects]);

  if (loading || isLoadingObjects) {
    return <div>Loading Modules...</div>;
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Available Modules</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {availableModules.map(module => (
          <div key={module.name} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="font-bold text-xl mb-2">{module.name}</h3>
            <p className="text-gray-600 mb-4">{module.description}</p>
            {activeModules.includes(module.name) ? (
              <Link 
                to={`/modules/${module.path}`}
                className="inline-block bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
              >
                Open
              </Link>
            ) : (
              <Link
                to={`/modules/${module.path}`}
                className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
              >
                Add
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ModulesPage;
