import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Array of pleasant colors for the notes
const NOTE_COLORS = [
  '#FFB3BA', // light pink
  '#BAFFC9', // light green
  '#BAE1FF', // light blue
  '#FFFFBA', // light yellow
  '#FFD9BA', // light orange
  '#E8BAFF', // light purple
  '#FFE4E1', // misty rose
  '#E0FFFF', // light cyan
  '#FFDAB9', // peach puff
  '#D8BFD8', // thistle
];

function GratitudeJarModulePage() {
  const [loading, setLoading] = useState(true);
  const [moduleData, setModuleData] = useState(null);
  const [noteText, setNoteText] = useState('');
  const [error, setError] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(getPreviousWeek());

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios.get('/api/modules/gratitudeJar/data', { withCredentials: true });
      setModuleData(res.data);
    } catch (err) {
      console.error('Error fetching Gratitude Jar data:', err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function getPreviousWeek() {
    const today = new Date();
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    return getWeekOfYear(lastWeek);
  }

  function getCurrentYear() {
    return new Date().getFullYear();
  }

  async function handleAddNote() {
    try {
      setError(null);
      await axios.post('/api/modules/gratitudeJar/addNote', 
        { 
          noteText, 
          weekNumber: selectedWeek 
        }, 
        { withCredentials: true }
      );
      setNoteText('');
      await fetchData();
    } catch (err) {
      if (err.response?.data?.error) {
        setError(err.response.data.error);
      }
      console.error('Error adding note:', err);
    }
  }

  async function handleUninstall(keepData = false) {
    try {
      setLoading(true);
      await axios.post(
        '/api/modules/gratitudeJar/uninstall',
        { keepData },
        { withCredentials: true }
      );
      setModuleData(null);
    } catch (err) {
      console.error('Error uninstalling module:', err);
    } finally {
      setLoading(false);
    }
  }

  // If module is not installed yet, show install button
  if (!loading && !moduleData) {
    return (
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Gratitude Jar</h2>
        <p className="mb-6 text-gray-600">Start your gratitude journey by adding weekly notes of appreciation.</p>
        <button
          onClick={async () => {
            try {
              await axios.post('/api/modules/gratitudeJar/install', {}, { withCredentials: true });
              await fetchData();
            } catch (err) {
              console.error('Error installing module:', err);
            }
          }}
          className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-3 rounded-lg 
                     hover:from-purple-600 hover:to-pink-600 transition-all duration-200 shadow-md"
        >
          Install Gratitude Jar
        </button>
      </div>
    );
  }

  if (loading) {
    return <div className="p-6">Loading Gratitude Jar...</div>;
  }

  const notes = moduleData.data.notesByWeek || {};
  const totalNotes = Object.keys(notes).length;
  const weekAlreadyHasNote = notes.hasOwnProperty(selectedWeek);

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">Gratitude Jar</h2>
      
      <div className="relative w-64 h-80 mx-auto mb-8">
        {/* Jar */}
        <div className="absolute inset-0 bg-gradient-to-b from-blue-50 to-blue-100 
                      border-4 border-blue-200 rounded-[2rem] rounded-t-3xl
                      shadow-inner overflow-hidden">
          {/* Lid */}
          <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 w-48 h-3 
                        bg-blue-300 rounded-lg"></div>
          
          {/* Notes */}
          {Object.entries(notes).map(([weekNum, note], idx) => {
            const left = Math.random() * 70 + 10;
            const top = Math.random() * 50 + 30;
            const rotate = Math.random() * 40 - 20;
            const colorIndex = idx % NOTE_COLORS.length;
            
            return (
              <div
                key={weekNum}
                className="absolute transform -translate-x-1/2 -translate-y-1/2 
                          w-10 h-10 flex items-center justify-center
                          rounded shadow-md cursor-pointer
                          hover:scale-110 transition-transform duration-200"
                style={{
                  left: `${left}%`,
                  top: `${top}%`,
                  backgroundColor: NOTE_COLORS[colorIndex],
                  transform: `translate(-50%, -50%) rotate(${rotate}deg)`
                }}
              >
                <span className="text-xs font-semibold">W{weekNum}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="text-center mb-6">
        <p className="text-lg font-medium text-gray-700">
          Gratitude notes {totalNotes}/52
        </p>
      </div>

      {error && (
        <div className="text-red-500 text-center mb-4">{error}</div>
      )}

      <div className="flex flex-col items-center gap-4">
        <select
          value={selectedWeek}
          onChange={(e) => setSelectedWeek(Number(e.target.value))}
          className="w-full max-w-md p-3 border border-gray-300 rounded-lg 
                   focus:ring-2 focus:ring-purple-500 focus:border-transparent"
        >
          <option value={getPreviousWeek()}>Last Week (Week {getPreviousWeek()})</option>
          <option value={getWeekOfYear(new Date())}>This Week (Week {getWeekOfYear(new Date())})</option>
        </select>

        {weekAlreadyHasNote ? (
          <p className="text-center text-gray-600 italic">
            You've already added a note for Week {selectedWeek}!
          </p>
        ) : (
          <>
            <textarea
              placeholder={`What were you grateful for in Week ${selectedWeek}?`}
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              className="w-full max-w-md p-3 border border-gray-300 rounded-lg 
                       focus:ring-2 focus:ring-purple-500 focus:border-transparent
                       resize-none h-24"
            />
            <button
              onClick={handleAddNote}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-2 
                       rounded-lg hover:from-purple-600 hover:to-pink-600 
                       transition-all duration-200 shadow-md"
            >
              Add Note
            </button>
          </>
        )}
      </div>

      <button
        onClick={() => {
          if (window.confirm('Are you sure you want to uninstall the Gratitude Jar Module? All your gratitude notes will be lost.')) {
            handleUninstall(false);
          }
        }}
        className="mt-8 block mx-auto text-red-500 hover:text-red-700 
                 transition-colors duration-200"
      >
        Uninstall Module
      </button>
    </div>
  );
}

// Same function as on the server for calculating the ISO-like week
function getWeekOfYear(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
  return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
}

export default GratitudeJarModulePage; 