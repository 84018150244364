import React, { useState, useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

function BrowserHistoryPage() {
  const { isSignedIn, isLoaded, getToken } = useAuth();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const token = await getToken();
        const response = await axios.get('/api/browser/history', {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
        setHistory(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching history:', err);
        setError('Failed to load browser history');
        setLoading(false);
      }
    };

    if (isSignedIn) {
      fetchHistory();
    }
  }, [isSignedIn, getToken]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!isSignedIn) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <div className="p-6">
        <div className="animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="space-y-3">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="h-4 bg-gray-200 rounded w-full"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Group consecutive visits to the same URL while maintaining chronological order
  const groupedHistory = history.reduce((groups, item) => {
    const date = new Date(item.accessedTime).toLocaleDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    
    const lastGroup = groups[date][groups[date].length - 1];
    
    if (lastGroup && lastGroup.url === item.url) {
      // Add to existing group if it's the same URL
      lastGroup.timestamps.push({
        time: item.accessedTime,
        title: item.title
      });
    } else {
      // Create new group for different URL
      groups[date].push({
        url: item.url,
        title: item.title, // Store the most recent title
        timestamps: [{
          time: item.accessedTime,
          title: item.title
        }]
      });
    }
    return groups;
  }, {});

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-8">Browsing History</h1>
      
      <div className="space-y-8">
        {Object.entries(groupedHistory).map(([date, items]) => (
          <div key={date} className="bg-white rounded-lg shadow overflow-hidden">
            <div className="bg-gray-50 px-4 py-2">
              <h2 className="text-lg font-medium text-gray-700">{date}</h2>
            </div>
            <div className="divide-y divide-gray-200">
              {items.map((item, index) => (
                <div key={index} className="px-4 py-3 hover:bg-gray-50">
                  <div className="flex items-start">
                    <div 
                      className="w-32 max-h-24 overflow-y-auto pr-4 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100" 
                      style={{
                        scrollbarWidth: 'thin',
                        msOverflowStyle: 'none'
                      }}
                    >
                      {item.timestamps
                        .sort((a, b) => new Date(b.time) - new Date(a.time))
                        .map((timestamp, idx) => (
                          <div key={idx} className="text-sm text-gray-500 py-0.5 whitespace-nowrap">
                            {new Date(timestamp.time).toLocaleTimeString()}
                          </div>
                        ))
                      }
                    </div>
                    <div className="flex-grow min-w-0 ml-6">
                      <a 
                        href={item.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 hover:underline block"
                      >
                        {item.title || item.url}
                      </a>
                      <div className="text-sm text-gray-500 truncate mt-1">
                        {item.url}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BrowserHistoryPage; 