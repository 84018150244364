// client/src/components/ObjectEditor.js

import React, { useState, useEffect } from 'react';
import { useError } from '../contexts/ErrorContext';

function ObjectEditor({ object, setObject, component, saveEdits }) {
    const [content, setContent] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const { showError } = useError();

    useEffect(() => {
        if (object && isEditing && content.length === 0) {
            setContent(JSON.stringify(object, null, 2));
        }
    }, [object, isEditing]);

    const handleSave = async () => {
        try {
            const updated = JSON.parse(content);
            await setObject(updated);
            await saveEdits();
            setIsEditing(false);
        } catch (error) {
            showError('Invalid JSON format');
        }
    };

    if (isEditing) {
        return (
            <>
                <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="w-full p-2 border rounded text-black"
                    rows={30}
                />
                <div className="mt-4 flex gap-2">
                    <button 
                        onClick={handleSave} 
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Save Object
                    </button>
                    <button
                        onClick={() => setIsEditing(false)}
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                    >
                        Cancel
                    </button>
                </div>
            </>
        );
    }

    return (
        <>
            <button
                onClick={() => setIsEditing(true)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mb-4"
            >
                Edit Object
            </button>
            {component()}
        </>
    );
}

export default ObjectEditor;