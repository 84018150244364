import React, { useState, useEffect, useRef } from 'react';
import { useError } from '../contexts/ErrorContext';
import { TagFaceModal } from '../components/TagFaceModal';
import { usePageTitle } from '../hooks/usePageTitle';

function PhotosPage() {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 10 * 24 * 60 * 60 * 1000), // 10 days ago
    endDate: new Date() // today
  });
  const [faces, setFaces] = useState({});
  const [detecting, setDetecting] = useState({});
  const [imagesSize, setImagesSize] = useState({});
  const { showError } = useError();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const imageRefs = useRef({}); // Single useRef for all images
  const [tagFaceData, setTagFaceData] = useState(null);

  usePageTitle('Photos');

  useEffect(() => {
    fetchPhotos();
  }, [dateRange]);

  // Initialize refs when photos change
  useEffect(() => {
    photos.forEach(photo => {
      if (!imageRefs.current[photo.id]) {
        imageRefs.current[photo.id] = React.createRef();
      }
    });
  }, [photos]);

  const fetchPhotos = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `/api/photos?startDate=${dateRange.startDate.toISOString()}&endDate=${dateRange.endDate.toISOString()}`,
        { credentials: 'include' }
      );
      const data = await response.json();
      if (response.ok && data) {
        setPhotos(data);
        // Initialize faces state from loaded photos
        const facesData = {};
        data.forEach(photo => {
          if (photo.faces?.length > 0) {
            facesData[photo.id] = photo.faces.map(face => ({
              id: face.id,
              ...face.box,
              descriptor: face.descriptor,
              personTag: photo.people?.find(p => p.faceId === face.id)?.name,
              personId: photo.people?.find(p => p.faceId === face.id)?.personId
            }));
          }
        });
        setFaces(facesData);
      } else {
        showError(data.error);
      }
    } catch (error) {
      console.error('Error fetching photos:', error);
      showError('Failed to fetch photos');
    } finally {
      setLoading(false);
    }
  };

  const handleImageLoad = (photoId, e) => {
    if (e.target) {
      setImagesSize(prev => ({
        ...prev,
        [photoId]: {
          width: e.target.clientWidth,
          height: e.target.clientHeight
        }
      }));
    }
  };

  const handleDetectFaces = async (photoId) => {
    try {
      setDetecting(prev => ({ ...prev, [photoId]: true }));
      // Clear existing faces for this photo
      setFaces(prev => ({
        ...prev,
        [photoId]: []
      }));

      const response = await fetch(`/api/photos/${photoId}/detectFaces`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const photo = await response.json();
        // Update faces state using the photo's faces and people data
        setFaces(prev => ({
          ...prev,
          [photoId]: photo.faces.map(face => ({
            id: face.id,
            ...face.box,
            descriptor: face.descriptor,
            personTag: photo.people?.find(p => p.faceId === face.id)?.name,
            personId: photo.people?.find(p => p.faceId === face.id)?.personId
          }))
        }));
      }
    } catch (error) {
      console.error('Error detecting faces:', error);
    } finally {
      setDetecting(prev => ({ ...prev, [photoId]: false }));
    }
  };

  const handleTagFace = async (photoId, faceId) => {
    const face = faces[photoId].find(f => f.id === faceId);
    if (!face) return;
    setTagFaceData({ photoId, face });
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Photos</h1>

      <div className="flex gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
          <input
            type="date"
            value={dateRange.startDate.toISOString().split('T')[0]}
            onChange={(e) => setDateRange(prev => ({
              ...prev,
              startDate: new Date(e.target.value)
            }))}
            className="border rounded p-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
          <input
            type="date"
            value={dateRange.endDate.toISOString().split('T')[0]}
            onChange={(e) => setDateRange(prev => ({
              ...prev,
              endDate: new Date(e.target.value)
            }))}
            className="border rounded p-2"
          />
        </div>
      </div>

      {loading ? (
        <div>Loading photos...</div>
      ) : photos.length === 0 ? (
        <div>No photos found for the selected date range</div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {photos.map((photo) => {
            return (
              <div key={photo.id} className="relative group">
                <img
                  ref={el => imageRefs.current[photo.id] = el}
                  src={photo.baseUrl}
                  alt={photo.filename}
                  className="w-full h-48 object-cover rounded"
                  onLoad={(e) => handleImageLoad(photo.id, e)}
                  onClick={() => setSelectedPhoto(photo)}
                />
                {/* Overlay with details and the "Detect Faces" button */}
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 opacity-0 group-hover:opacity-100 transition-opacity">
                  <div>{photo.filename}</div>
                  <div className="text-sm">{photo.path}</div>
                  <div className="text-sm">
                    {new Date(photo.mediaMetadata.creationTime).toLocaleString()}
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDetectFaces(photo.id);
                    }}
                    disabled={detecting[photo.id]}
                    className="absolute top-2 right-2 bg-white text-black px-4 py-2 rounded shadow"
                  >
                    {detecting[photo.id]
                      ? 'Detecting...'
                      : faces[photo.id]?.length > 0
                        ? 'Re-detect Faces'
                        : 'Detect Faces'
                    }
                  </button>
                </div>
                {/* Render detected face boxes as overlays */}
                {faces[photo.id] && imagesSize[photo.id] && faces[photo.id].map((face, index) => {
                  // Multiply the relative (0..1) face coordinates by the rendered width/height:
                  const left = face.left * imagesSize[photo.id].width;
                  const top = face.top * imagesSize[photo.id].height;
                  const width = face.width * imagesSize[photo.id].width;
                  const height = face.height * imagesSize[photo.id].height;

                  return (
                    <>
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTagFace(photo.id, face.id);
                        }}
                        style={{
                          position: 'absolute',
                          border: '2px solid red',
                          left,
                          top,
                          width,
                          height,
                          cursor: 'pointer'
                        }}
                        title={face.personTag ? `Tagged: ${face.personTag}` : 'Click to tag face'}
                      />
                      {face.personTag && (
                        <div
                          style={{
                            position: 'absolute',
                            left,
                            top: top - 20,
                            background: 'rgba(0,0,0,0.7)',
                            color: 'white',
                            padding: '2px 4px',
                            borderRadius: '2px',
                            fontSize: '12px'
                          }}
                        >
                          {face.personTag}
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}

      {selectedPhoto && (
        <PhotoModal
          photo={selectedPhoto}
          facesData={faces[selectedPhoto.id]}
          onTagFace={(faceId) => handleTagFace(selectedPhoto.id, faceId)}
          onClose={() => setSelectedPhoto(null)}
        />
      )}

      {tagFaceData && (
        <TagFaceModal
          face={tagFaceData.face}
          photoId={tagFaceData.photoId}
          onSubmit={async ({ name, isMe }) => {
            try {
              const response = await fetch(`/api/photos/${tagFaceData.photoId}/tagFace`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                  faceId: tagFaceData.face.id,
                  name,
                  isMe
                })
              });

              if (response.ok) {
                const { photo } = await response.json();
                // Update both faces and photos state
                setFaces(prev => ({
                  ...prev,
                  [tagFaceData.photoId]: photo.faces.map(face => ({
                    id: face.id,
                    ...face.box,
                    descriptor: face.descriptor,
                    personTag: photo.people?.find(p => p.faceId === face.id)?.name,
                    personId: photo.people?.find(p => p.faceId === face.id)?.personId
                  }))
                }));
                // Update the photos array to include new people data
                setPhotos(prev => prev.map(p =>
                  p.id === tagFaceData.photoId ? { ...p, people: photo.people } : p
                ));
              }
            } catch (error) {
              console.error('Error tagging face:', error);
            }
            setTagFaceData(null);
          }}
          onClose={() => setTagFaceData(null)}
        />
      )}
    </div>
  );
}

function PhotoModal({ photo, facesData, onTagFace, onClose }) {
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [editing, setEditing] = useState(false);
  const [photoDetails, setPhotoDetails] = useState({
    description: photo.description || '',
    location: photo.otherInfo?.location || '',
    activity: photo.otherInfo?.activity || '',
    mood: photo.otherInfo?.mood || ''
  });
  const imgRef = useRef(null);
  const { showError } = useError();

  useEffect(() => {
    if (imgRef.current) {
      setImgSize({
        width: imgRef.current.naturalWidth,
        height: imgRef.current.naturalHeight
      });
    }
  }, [photo]);

  const handleSave = async () => {
    try {
      const response = await fetch(`/api/photos/${photo.id}/details`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          description: photoDetails.description,
          otherInfo: {
            location: photoDetails.location,
            activity: photoDetails.activity,
            mood: photoDetails.mood
          }
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update photo details');
      }

      setEditing(false);
    } catch (error) {
      console.error('Error updating photo details:', error);
      showError('Failed to update photo details');
    }
  };

  if (!photo) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative max-w-7xl w-full mx-4">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="flex">
            {/* Left side - Image */}
            <div className="flex-1 relative">
              <img
                ref={imgRef}
                src={photo.baseUrl}
                alt={photo.filename}
                className="max-h-[80vh] object-contain"
              />
              {imgSize.width > 0 && facesData?.map((face, index) => {
                const left = face.left * imgSize.width;
                const top = face.top * imgSize.height;
                const width = face.width * imgSize.width;
                const height = face.height * imgSize.height;
                return (
                  <React.Fragment key={index}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        onTagFace(face.id);
                      }}
                      style={{
                        position: 'absolute',
                        border: '2px solid red',
                        left,
                        top,
                        width,
                        height,
                        cursor: 'pointer'
                      }}
                      title={face.personTag ? `Tagged: ${face.personTag}` : 'Click to tag face'}
                    />
                    {face.personTag && (
                      <div
                        style={{
                          position: 'absolute',
                          left,
                          top: top - 20,
                          background: 'rgba(0,0,0,0.7)',
                          color: 'white',
                          padding: '2px 4px',
                          borderRadius: '2px',
                          fontSize: '12px'
                        }}
                      >
                        {face.personTag}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>

            {/* Right side - Details */}
            <div className="w-96 bg-white p-6 overflow-y-auto max-h-[80vh]">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold">Photo Details</h3>
                <button
                  onClick={onClose}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>

              <div className="space-y-4">
                {editing ? (
                  <>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Description
                      </label>
                      <textarea
                        value={photoDetails.description}
                        onChange={(e) => setPhotoDetails(prev => ({
                          ...prev,
                          description: e.target.value
                        }))}
                        className="w-full p-2 border rounded-md"
                        rows={4}
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Location
                      </label>
                      <input
                        type="text"
                        value={photoDetails.location}
                        onChange={(e) => setPhotoDetails(prev => ({
                          ...prev,
                          location: e.target.value
                        }))}
                        className="w-full p-2 border rounded-md"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Activity
                      </label>
                      <input
                        type="text"
                        value={photoDetails.activity}
                        onChange={(e) => setPhotoDetails(prev => ({
                          ...prev,
                          activity: e.target.value
                        }))}
                        className="w-full p-2 border rounded-md"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Mood
                      </label>
                      <input
                        type="text"
                        value={photoDetails.mood}
                        onChange={(e) => setPhotoDetails(prev => ({
                          ...prev,
                          mood: e.target.value
                        }))}
                        className="w-full p-2 border rounded-md"
                      />
                    </div>

                    <div className="flex justify-end space-x-2 mt-4">
                      <button
                        onClick={() => setEditing(false)}
                        className="px-4 py-2 text-gray-700 border rounded-md hover:bg-gray-50"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <h4 className="font-medium text-gray-700">Description</h4>
                      <p className="text-gray-600">{photoDetails.description || 'No description'}</p>
                    </div>

                    <div>
                      <h4 className="font-medium text-gray-700">Location</h4>
                      <p className="text-gray-600">{photoDetails.location || 'No location'}</p>
                    </div>

                    <div>
                      <h4 className="font-medium text-gray-700">Activity</h4>
                      <p className="text-gray-600">{photoDetails.activity || 'No activity'}</p>
                    </div>

                    <div>
                      <h4 className="font-medium text-gray-700">Mood</h4>
                      <p className="text-gray-600">{photoDetails.mood || 'No mood'}</p>
                    </div>

                    <button
                      onClick={() => setEditing(true)}
                      className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 w-full"
                    >
                      Edit Details
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotosPage;