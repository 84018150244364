import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';
import { usePageTitle } from '../hooks/usePageTitle';

function ObjectLoader({ objectId: propObjectId, component }) {
    const { objectId: paramObjectId } = useParams();
    const { showError } = useError();
    const [object, setObject] = useState(null);
    const [savingCounter, setSavingCounter] = useState(0);
    const [isSaving, setIsSaving] = useState(false);

    const objectId = propObjectId || paramObjectId;

    const loadObject = async () => {
        try {
            const response = await axios.get(`/api/objects/${objectId}`, { withCredentials: true });
            setObject(response.data.object);
        } catch (error) {
            console.error('Error loading object:', error);
            showError('Error loading object:', error);
        }
    };

    usePageTitle(object?.name);

    useEffect(() => {
        if (objectId && objectId !== 'new') {
            loadObject();
        }
    }, [objectId]);

    // note: this is defectuous, i use saveEdits right after setObject all the time, i should change usage, but no time to refactor
    const saveEdits = async () => {
        setSavingCounter((prev) => prev + 1);
        await new Promise(resolve => setTimeout(resolve, 100));
        setSavingCounter((prev) => prev - 1);
    };

    const saveObject = async () => {
        if (!objectId || !object) {
            return;
        }

        try {
            await axios.put(
                `/api/objects/${objectId}`,
                object,
                { withCredentials: true }
            );
        } catch (error) {
            console.error('Error updating object:', error);
            showError('Error updating object:', error);
        }
    };

    useEffect(() => {
        setIsSaving(savingCounter > 0);
        
        saveObject();
    }, [savingCounter]);

    // const handleSetObject = async (newObject) => {
    //     setObject(newObject);
    //     await saveEdits();
    // };

    if (!object && objectId !== 'new') {
        return <div>Loading...</div>;
    }

    return component(object, setObject, objectId, saveEdits, isSaving, loadObject);
}

export default ObjectLoader; 