import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';
import { useObjects } from '../../contexts/ObjectsContext';
import { usePageTitle } from '../../hooks/usePageTitle';

function DailyCompanionPage() {
  const [isInstalling, setIsInstalling] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const [moduleData, setModuleData] = useState(null);
  const navigate = useNavigate();
  const { showError } = useError();
  const { objects, getObjects } = useObjects();

  usePageTitle('Daily Companion Module');

  useEffect(() => {
    getObjects();
  }, []);

  useEffect(() => {
    const foundModuleData = objects.find(
      obj => obj.type === 'ModuleData' && obj.name === 'DailyCompanionModule'
    );
    setModuleData(foundModuleData);
    setIsInstalled(!!foundModuleData);
  }, [objects]);

  const handleInstall = async () => {
    setIsInstalling(true);
    try {
      const response = await fetch('/api/modules/dailyCompanion/install', {
        method: 'POST',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to install module');
      }

      await getObjects();
      setIsInstalled(true);
    } catch (error) {
      console.error('Error installing Daily Companion module:', error);
      showError('Failed to install Daily Companion module');
    } finally {
      setIsInstalling(false);
    }
  };

  const handleUninstall = async () => {
    try {
      const response = await fetch('/api/modules/dailyCompanion/uninstall', {
        method: 'POST',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to uninstall module');
      }

      await getObjects();
      setIsInstalled(false);
      navigate('/modules');
    } catch (error) {
      console.error('Error uninstalling Daily Companion module:', error);
      showError('Failed to uninstall Daily Companion module');
    }
  };

  if (!isInstalled) {
    return (
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Daily Companion Module</h2>
        <p className="mb-4">
          This module analyzes your daily photos and activities to provide insights and memories.
        </p>
        <button
          onClick={handleInstall}
          disabled={isInstalling}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors disabled:bg-gray-400"
        >
          {isInstalling ? 'Installing...' : 'Install Module'}
        </button>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Daily Companion</h2>
        <button
          onClick={handleUninstall}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
        >
          Uninstall Module
        </button>
      </div>
      <div className="bg-white rounded-lg shadow p-4">
        <p className="mb-4">Your daily photos and activities will be analyzed automatically.</p>
        {moduleData?.data?.jobId && (
          <div className="mt-4">
            <p className="text-sm text-gray-600 mb-2">Photo Processing Job:</p>
            <Link 
              to={`/jobs/${moduleData.data.jobId}`}
              className="text-blue-500 hover:text-blue-600 underline"
            >
              View Job Status
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default DailyCompanionPage; 