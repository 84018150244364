import { useState, useEffect } from 'react';
import axios from 'axios';

export function useUserSettings() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/user', { withCredentials: true });
      setUser(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateSettings = async (path, value) => {
    try {
      setLoading(true);
      const pathArray = path.split('.');
      const updatedUser = { ...user };
      let current = updatedUser;
      
      for (let i = 0; i < pathArray.length - 1; i++) {
        if (!current[pathArray[i]]) {
          current[pathArray[i]] = {};
        }
        current = current[pathArray[i]];
      }
      current[pathArray[pathArray.length - 1]] = value;

      const response = await axios.patch('/api/user', updatedUser, { 
        withCredentials: true 
      });
      setUser(response.data);
      return response.data;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { settings: user, loading, error, updateSettings };
} 