// client/src/components/Dashboard.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';
import FileSystemViewer from '../components/FileSystemViewer';
import Calendar from '../components/Calendar';
import { useObjects } from '../contexts/ObjectsContext';
import { usePageTitle } from '../hooks/usePageTitle';

function Dashboard() {
  const navigate = useNavigate();
  const { showError } = useError();
  const { objects, getObjects, isLoading } = useObjects();
  const [expandedSections, setExpandedSections] = useState({
    Apps: true,
    Conversations: false,
    Notes: false,
    Jobs: true,
    Canvases: false
  });

  usePageTitle('Dashboard');

  // Sort objects by date in descending order
  const sortedObjects = [...objects].sort((a, b) => {
    const dateA = new Date(a.date || a.start?.dateTime || 0);
    const dateB = new Date(b.date || b.start?.dateTime || 0);
    return dateB - dateA;
  });

  useEffect(() => {
    getObjects();
  }, []);

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const canvases = sortedObjects.filter(obj => obj.type === 'Canvas');
  const apps = sortedObjects.filter(obj => obj.type === 'App');
  const conversations = sortedObjects.filter(obj => obj.type === 'Conversation');
  const notes = sortedObjects.filter(obj => obj.type === 'Note');
  const jobs = sortedObjects.filter(obj => obj.type === 'Job');

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl font-bold text-gray-600">Loading...</div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          {/* Apps Section */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <button onClick={() => toggleSection('Apps')} className="mr-2 text-gray-500 focus:outline-none">
                  {expandedSections.Apps ? '▼' : '▶'}
                </button>
                <h2 className="text-2xl font-bold">Apps</h2>
                <span className="ml-2 text-gray-500">({apps.length})</span>
              </div>
              <button onClick={() => navigate('/apps/new')} className="bg-purple-500 text-white px-4 py-2 rounded hover:opacity-90">
                New App
              </button>
            </div>
            {expandedSections.Apps && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {apps.map(app => (
                  <div key={app.id} onClick={() => navigate(`/apps/${app.id}`)} 
                    className="p-4 border rounded cursor-pointer hover:bg-gray-50">
                    <h3 className="font-bold">{app.name}</h3>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Conversations Section */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <button onClick={() => toggleSection('Conversations')} className="mr-2 text-gray-500 focus:outline-none">
                  {expandedSections.Conversations ? '▼' : '▶'}
                </button>
                <h2 className="text-2xl font-bold">Conversations</h2>
                <span className="ml-2 text-gray-500">({conversations.length})</span>
              </div>
              <button onClick={() => navigate('/conversations/new')} className="bg-green-500 text-white px-4 py-2 rounded hover:opacity-90">
                New Conversation
              </button>
            </div>
            {expandedSections.Conversations && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {conversations.map(conversation => (
                  <div key={conversation.id} onClick={() => navigate(`/conversations/${conversation.id}`)}
                    className="p-4 border rounded cursor-pointer hover:bg-gray-50">
                    <h3 className="font-bold">{conversation.name}</h3>
                    <p className="text-sm text-gray-600">
                      model: {conversation.configuration?.model || 'N/A'}<br />
                      <span className="text-xs">
                        {new Date(conversation.date || conversation.start?.dateTime).toLocaleDateString()}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Notes Section */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <button onClick={() => toggleSection('Notes')} className="mr-2 text-gray-500 focus:outline-none">
                  {expandedSections.Notes ? '▼' : '▶'}
                </button>
                <h2 className="text-2xl font-bold">Notes</h2>
                <span className="ml-2 text-gray-500">({notes.length})</span>
              </div>
              <button onClick={() => navigate('/notes/new')} className="bg-blue-500 text-white px-4 py-2 rounded hover:opacity-90">
                New Note
              </button>
            </div>
            {expandedSections.Notes && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {notes.map(note => (
                  <div key={note.id} onClick={() => navigate(`/notes/${note.id}`)}
                    className="p-4 border rounded cursor-pointer hover:bg-gray-50">
                    <h3 className="font-bold">{note.name}</h3>
                    <p className="text-sm text-gray-600">
                      {note.content?.length > 30 ? note.content.substring(0, 30) + '...' : note.content}<br />
                      <span className="text-xs">
                        {new Date(note.date || note.start?.dateTime).toLocaleDateString()}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Jobs Section */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <button onClick={() => toggleSection('Jobs')} className="mr-2 text-gray-500 focus:outline-none">
                  {expandedSections.Jobs ? '▼' : '▶'}
                </button>
                <h2 className="text-2xl font-bold">Jobs</h2>
                <span className="ml-2 text-gray-500">({jobs.length})</span>
              </div>
              <button onClick={() => navigate('/jobs/new')} className="bg-orange-500 text-white px-4 py-2 rounded hover:opacity-90">
                New Job
              </button>
            </div>
            {expandedSections.Jobs && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {jobs.map(job => (
                  <div key={job.id} onClick={() => navigate(`/jobs/${job.id}`)}
                    className="p-4 border rounded cursor-pointer hover:bg-gray-50">
                    <h3 className="font-bold">{job.name}</h3>
                    <p className="text-sm text-gray-600">
                      enabled: {job.enabled ? '✅' : '❌'} {String(job.enabled)}<br />
                      <span className="text-xs">
                        Next run: {job.nextRun ? new Date(job.nextRun).toLocaleDateString() : 'N/A'}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Canvases Section */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <button onClick={() => toggleSection('Canvases')} className="mr-2 text-gray-500 focus:outline-none">
                  {expandedSections.Canvases ? '▼' : '▶'}
                </button>
                <h2 className="text-2xl font-bold">Canvases</h2>
                <span className="ml-2 text-gray-500">({canvases.length})</span>
              </div>
              <button onClick={() => navigate('/canvases/new')} className="bg-yellow-500 text-white px-4 py-2 rounded hover:opacity-90">
                New Canvas
              </button>
            </div>
            {expandedSections.Canvases && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {canvases.map(canvas => (
                  <div key={canvas.id} onClick={() => navigate(`/canvases/${canvas.id}`)}
                    className="p-4 border rounded cursor-pointer hover:bg-gray-50">
                    <h3 className="font-bold">{canvas.name}</h3>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div>
          <FileSystemViewer objects={objects} />
        </div>
      </div>
      <Calendar />
    </div>
  );
}

export default Dashboard;
