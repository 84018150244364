import React, { useState, useEffect } from 'react';
import { PersonSelector } from './PersonSelector';

export function TagFaceModal({ face, onSubmit, onClose }) {
    const [name, setName] = useState(
        face.potentialMatches?.length > 0 ? face.potentialMatches[0].name : ''
    );
    const [isMe, setIsMe] = useState(false);
    const [people, setPeople] = useState([]);

    useEffect(() => {
        fetchPeople();
    }, []);

    const fetchPeople = async () => {
        try {
            const response = await fetch('/api/people', { credentials: 'include' });
            if (response.ok) {
                const data = await response.json();
                setPeople(data);
            }
        } catch (error) {
            console.error('Error fetching people:', error);
        }
    };

    const handlePersonSelect = (person) => {
        setName(person.name);
        setIsMe(person.isMe || false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, isMe });
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full">
                <h3 className="text-lg font-medium mb-4">Tag Face</h3>

                <form onSubmit={handleSubmit}>
                    {face.potentialMatches?.length > 0 && (
                        <div className="mb-4">
                            <p className="text-sm text-gray-600">
                                Potential match: {face.potentialMatches[0].name}
                                ({Math.round(face.potentialMatches[0].confidence * 100)}% confidence)
                            </p>
                        </div>
                    )}

                    {people.length > 0 ? (
                        <PersonSelector
                            people={people}
                            onSelect={handlePersonSelect}
                        />
                    ) : (
                        <div className="mb-4">
                            Loading people...
                        </div>
                    )}

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Name
                        </label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full border rounded-md px-3 py-2"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                checked={isMe}
                                onChange={(e) => setIsMe(e.target.checked)}
                                className="mr-2"
                            />
                            <span className="text-sm text-gray-700">This is me</span>
                        </label>
                    </div>

                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-sm text-white bg-blue-500 hover:bg-blue-600 rounded"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
} 