import React from 'react';

export function PersonSelector({ people, onSelect }) {
  return (
    <div className="mb-4">
      <h4 className="text-sm font-medium text-gray-700 mb-2">Existing People</h4>
      <div className="flex flex-wrap gap-2">
        {people.map(person => (
          <button
            key={person.id}
            onClick={() => onSelect(person)}
            className="px-3 py-1 bg-gray-100 hover:bg-gray-200 rounded-full text-sm"
          >
            {person.name}
          </button>
        ))}
      </div>
    </div>
  );
} 