import React from 'react';
import { useUserSettings } from '../hooks/useUserSettings';

function SettingsManager({ section, fields }) {
  const { settings, loading, error, updateSettings } = useUserSettings();

  if (loading) return <div>Loading settings...</div>;
  if (error) return <div>Error loading settings: {error}</div>;

  const getValue = (path) => {
    const value = path.split('.').reduce((obj, key) => obj?.[key], settings);
    return value === undefined ? [] : value;
  };

  const handleArrayChange = async (path, index, value) => {
    try {
      const currentArray = [...getValue(path)];
      
      // Update existing value
      if (index < currentArray.length) {
        currentArray[index] = value.trim();
      } 
      // Add new value
      else if (value.trim()) {
        currentArray.push(value.trim());
      }

      // Remove empty values
      const cleanArray = currentArray.filter(Boolean);
      await updateSettings(path, cleanArray);
    } catch (err) {
      console.error('Failed to update setting:', err);
    }
  };

  const handleRemoveArrayItem = async (path, index) => {
    try {
      const currentArray = [...getValue(path)];
      currentArray.splice(index, 1);
      await updateSettings(path, currentArray);
    } catch (err) {
      console.error('Failed to remove item:', err);
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">{section}</h2>
      <div className="space-y-4">
        {fields.map(({ label, path, type = 'text', description }) => (
          <div key={path} className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {label}
            </label>
            {description && (
              <p className="text-sm text-gray-500">{description}</p>
            )}
            {type === 'array' ? (
              <div className="space-y-2">
                {[...getValue(path), ''].map((value, index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      type="text"
                      value={value}
                      onChange={(e) => handleArrayChange(path, index, e.target.value)}
                      className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      placeholder={index === getValue(path).length ? "Add new path..." : ""}
                    />
                    {index < getValue(path).length && (
                      <button
                        onClick={() => handleRemoveArrayItem(path, index)}
                        className="px-2 py-1 text-sm text-red-600 hover:text-red-800"
                        type="button"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
              </div>
            ) : type === 'boolean' ? (
              <input
                type="checkbox"
                checked={Boolean(getValue(path))}
                onChange={(e) => updateSettings(path, e.target.checked)}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
            ) : (
              <input
                type={type}
                value={getValue(path)}
                onChange={(e) => updateSettings(path, e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SettingsManager; 